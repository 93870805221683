.flex_row {
  display:flex;
  flex-direction:row;
}
/*To change to Tailwind...  flex flex-row*/

.flex_column {
  display:flex;
  flex-direction:column;
}
/*To change to Tailwind...  flex flex-col*/

.documentation_container {
  flex-grow:1;
}
/*To change to Tailwind...  grow  */

.documentation_container_outlet {
  align-items:stretch;
  padding:24px 24px 40px;
  gap: 24px;
}
/* To change to Tailwind... items-stretch pt-6 pb-10 pr-6 gap-6 */

.module_container {
  gap:24px;
}
/* To change to Tailwind... gap-6 */
.top_section {
  gap:8px;
}
/* To change to Tailwind...  gap-2*/
.top_section_header {
  justify-content: space-between;
  align-items:center;
}
/* To change to Tailwind...  content-between  items-center */

.header_buttons {
  gap:16px;
  white-space: nowrap;
  align-items:center;
  align-self: flex-start;
}
/* To change to Tailwind...  gap-4 whitespace-nowrap items-center self-start */

.line {
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
/* To change to Tailwind... border-solid border-1 border-[#CCC] */

.module_information {
  gap:24px;
}
/* To change to Tailwind... gap-6 */
.document_group_link {
  line-height:60px;
  text-decoration:none;
  color:#999;
  font-weight:bold;
  padding-left:16px;
  white-space:nowrap;
}
/* To change to Tailwind... leading-[3.75rem] no-underline text-[#999] font-bold pl-4 whitespace-nowrap  */

.document_group_link.active {
  color:#000;
  border-left:4px solid #567783;
  padding-left:12px;
}
/* To change to Tailwind... text-black  */

.group_select {
  border: 1px solid #999;
  padding:12px 8px 12px 16px;
  font-size: var(--fs16px);
}
/* To change to Tailwind...  */

.flex_column.module_information > div {
  gap:10px;
}
/* To change to Tailwind...  */

.add_document_confirmation_buttons {
  gap:16px;
}
/* To change to Tailwind...  */

.manageButtonImage {
  display:flex;
  gap:8px;
  align-items:center;
  height: 16px;
}
/* To change to Tailwind...  */