.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.warning_text span {
  margin: 0;
  font-size: var(--fs14px);
  font-weight: 300;
}

.select_container {
  border-bottom: 1px solid #999;
  font-size: var(--fs16px);
  flex-grow: 1;
}

.selected {
  color: var(--primaryGreyFont);
}

.placeholder {
  color: var(--secondaryGreyFont);
}

.select {
  justify-content: space-between;
  padding: 4px 4px 4px 4px;
  cursor: pointer;
  font-size: var(--fs16px);
}

.select_options {
  gap: 3px;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  z-index: 1;
  overflow: scroll !important;
  margin-right: 30px;
  max-height: 225px;
  width: 100%;
}

.select_options::-webkit-scrollbar {
  display: none;
}

.select_options > div {
  color: var(--primaryGreyFont);
  padding: 4px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
}

.select_options > div:hover {
  background-color: #f2f2f2;
}

.checkbox_label {
  font-family: "DIN Next LT Pro";
  font-size: var(--fs16px);
}

.delete_file {
  cursor: pointer;
}

.form_field_row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.input {
  border: none;
  height: 32px;
  border-bottom: 1px solid #b3b3b3;
  flex-grow: 1;
  font-size: 16px;
  width: 100%;
  padding: 1px 4px;
}

.input:focus-visible {
  outline: none;
}

.small {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.input:disabled {
  border-bottom: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.cursor_pointer {
  cursor: pointer;
}

.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}

.input_label {
  flex-basis: 30%;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
}

.form_group {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 70%;
  position: relative;
}

.errorField {
  background-color: #fee2e2;
}

.error_text {
  color: #991b1b;
}

.deleteFile {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.add_document_confirmation_buttons {
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .form_field_row {
    flex-wrap: wrap;
  }

  .input_label {
    flex-basis: 100%;
  }

  .input {
    height: 48px;
    border: 1px solid #999;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
