.searchGlobalWrapper {
    font-size: 16px;
    background-color: #fff;
    padding: 45px;
    box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 5%);
    border-radius: 8px;
  }
  
  .card {
    background-color: white;
    border-radius: 8px;
    margin-right: 24px;
    margin-bottom: 20px;
    position: relative;
    padding: 24px 24px 24px 24px;
  }
  .GolbalSContent {
    display: block;
    margin-bottom: 32px;
  }
  
  .filterRow{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
  .checkboxWrp{
    margin-bottom: 24px;
  }
  .checkboxRow{
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
  .filter_tooltip_content {
    width: -webkit-fill-available;
    padding:24px;
    gap:24px;
  }
  
  .filterForm {
      min-width: 290px;
  }
  
  @media (max-width: 767px) {
    .filterForm {
      min-width: auto;
    }
  
    .filterRow {
      display: block;
    }
    .filterRow .loginHeader {
      margin-bottom: 15px !important;
      display: block;
      width: 100%;
    }
    .filterRow .filterWrapper > div {
      left: 4px !important;
      right: 4px !important;
    }
  }