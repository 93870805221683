
.flex_column {
    display:flex;
    flex-direction:column;
}

.flex_row {
    display:flex;
    flex-direction:row;
}

.report_container {
    gap:20px;
    flex-grow:1;
}

.options {
    justify-content: space-between;
    align-items:center;
    gap:10px;
}
.is_mobile .options {
    align-items:stretch;
}
.options_left {
    gap:16px;
    flex-grow:1;
    font-size:var(--fs14px);
    align-items:center;
    align-self:stretch;
}

.results_container {
    font-size:var(--fs14px);
    gap:8px;
}
.is_desktop .results_container {
    gap:0;
}
.is_desktop .results_container > div {
    padding:12px 24px;
    border-bottom: 1px solid #F2F2F2;
    align-items: center;    
}
.is_desktop .results_container > div:last-child {
    border-bottom: 0
}

.table_heading {
    border-radius:4px;
    background-color:#F2F2F2;
    font-weight:700;
}
.accordion_heading {
    gap:14px;
    font-weight:700;
    padding-left:12px;
}
.checkbox {
    display:flex;
}
.checkboxWrapper{
    display: block;
}
.checkboxRow{
    display: block;
    margin-bottom: 8px;
}
.checkboxRow:last-child{
    margin-bottom: 0
}
.checkboxWrapper label{
    text-transform: capitalize;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    height: 16px;
    width: 16px;
    border-radius: 2.67px;
    border: 0.666667px solid #999999;
    background-color: white;
    position:relative;
    cursor:pointer;
}
.checkmark:hover {
    background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
    background-color: white;
    border: 0.666667px solid #3C5D69;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: flex;
}
.checkbox .checkmark:after {
    height: 88%;
    left: 7%;
    top: 6%;
    width: 87%;
    background-color: #3C5D69;
    border-radius: 1.33333px;
}
/* ------------------- */

.someCheckmark {
    height: 16px;
    width: 16px;
    border-radius: 2.67px;
    border: 0.666667px solid #999999;
    background-color: white;
    position:relative;
    cursor:pointer;
}
.someCheckmark:hover {
    background-color: #ccc;
}

.checkbox input:checked ~ .someCheckmark {
    background-color: white;
    border: 0.666667px solid #3C5D69;
}
.someCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .someCheckmark:after {
    display: flex;
}
.checkbox .someCheckmark:after {
    left: 1px;
    top: 6px;
    width: 12px;
    height: 2px;
    background-color: #3C5D69;
    border-radius: 1.33333px;
}


.checkmark_container {
    flex-basis:57px;
    align-items:center;
}
.bulk_action_button {
    display:flex;
    margin-left:4px;
    border-radius:4px;
    filter:var(--svgFilterGrey);
}
.bulk_action_button.has_checked_rows {
    cursor:pointer;
    filter:none;
}
.bulk_action_button.has_checked_rows:hover {
    background-color:#e2e2e2;
}

.accordion_title {
    gap:40px;
    flex-grow:1;
    padding:14px 0 14px 12px;
    color:#00749E;
}
.accordion_title.unread {
    font-weight:bold;
}
.result_container {
    gap:24px;
    padding: 24px 0 0;
    align-items:flex-start;
}
.is_tablet .result_container {
    gap:0;
    padding:0;
}
.data_point {
    align-self:stretch;
}
.is_tablet .data_point + .data_point {
    border-top: 1px solid #E6E6E6;
}
.data_point > div {
    padding: 8px 24px
}

.is_tablet .data_point > div {
    flex-basis:50%;
}

.is_desktop .data_point > div {
    padding:0;
}
.more_actions {
    gap:8px;
    align-items:center;
    cursor:pointer;
}
.is_desktop .more_actions {
    filter:none;
}
.pagination {
    justify-content:space-between;
    align-items:center;
    gap:16px;
}
.pagination .pagination_left {
    gap:16px;
    align-items:center;
}

.pagination .page_numbers {
    align-items:center;
}
.pagination .page_numbers .page_number {
    color: #3C5D69;
    margin: 0 4px;
    padding: 4px 9px;
    cursor:pointer;
}
.pagination .page_numbers .page_number.active {
    font-weight:700;
    background: #E6ECEE;
    border-radius:4px;
}
.delete_modal_body,
.delete_confirm_body {
    gap:32px;
}
.delete_modal_body > *,
.delete_confirm_body > * {
    padding:0;
    margin:0;
}
.delete_modal_body ul,
.move_modal_body ul {
    list-style-type:none;
    font-size:var(--fs14px);
    color:#666;
}
.delete_confirm_body .flex_row {
    justify-content: space-around;
}
.delete_modal_body > .flex_row {
    gap:16px;
}

.move_modal_body {
    display: flex;
}
.move_modal_body > p,
.move_modal_body > ul {
    padding:0;
    margin:0;
}
.move_document_preposition {
    gap:24px;
}
.move_document_preposition_label {
    flex-basis: 180px;
}
.move_document_preposition *:not(.move_document_preposition_label) {
    flex-grow:1;
    padding:12px 8px 12px 16px;
}
.move_document_footer {
    justify-content: space-between;
}
.move_document_footer_left {
    gap:16px;
}
.filter_tooltip_content {
    width:288px;
    padding:24px;
    gap:24px;
    height: 50vh;
    overflow: scroll;
}
.filter_tooltip_content::-webkit-scrollbar {
    display: none;
}

.filter_tooltip_field {
    gap:8px;
}
.filter_tooltip_field > label {
    white-space: nowrap;
    font-weight:bold;
}
.filter_tooltip_field > select {
    flex-grow:1;
    padding: 12px 8px 12px 16px;
}
.equipment_option_checkbox_list {
    gap:8px;
}
.filter_pill_container {
    display:flex;
    flex-direction:row;
    gap:24px;
    flex-wrap: wrap;
}
.filter_pill {
    padding:4px 12px;
    color:#000;
    background:#CDD9DD;
    font-weight:normal;
    display:flex;
    flex-direction:row;
    gap:10px;
    align-items:center;
    white-space: nowrap;
}

.report_table {
    border-collapse:collapse;
}
.report_table th {
    text-align:left;
    background: #F2F2F2;
}
.report_table th, .report_table td {
    padding:12px;
}
.report_table .unread td {
    background: rgba(0, 116, 158, 0.05)
}
.report_table .unread .document_name,
.unread .report_table_accordion .more_actions {
    font-weight:bold;
}
.report_table.report_table_accordion th {
    background:none;
    padding-left:15px;
}
.report_table.report_table_accordion tr + tr > * {
    border-top:1px solid #E6E6E6;
}
.report_table_accordion {
    width:100%;
}
.unread .report_table_accordion tr > * {
    background: rgba(0, 116, 158, 0.05)
}

/* styles to override component styles */
.search_keyword_container {
    border: 1px solid #999;
    border-radius:4px;
    padding-left:4px;
    padding-right:7px;
    gap:8px;
    align-items:center;
    flex-grow:1;
}
.is_desktop .search_keyword_container {
    flex-basis:30%;
    flex-grow:unset;
    height: 36px;
}
.search_keyword,
.search_keyword:focus {
    border:none;
    outline:none;
    line-height:28px;
    width:100%;
}
.search_keyword_container img {
    height:18px;
    width:18px;
}

.reports_dropdown {
    line-height:28px;
}
.reports_dropdown div[id="selectDropdown"] {
    font-weight: normal !important;
    font-size: 14px !important;
    background-color: white !important;
    border: 1px solid #999999 !important;
    height: 100% !important;
}
.reports_dropdown div[class*="input-box"] {
    margin:0;
}

.copy_link_textbox {
	height: 36px !important;
	border-radius: 4px;
	border-width: 1px;
	width: 50%;
}

.plainselect_container {
    border-bottom: 1px solid #999;
    font-size: var(--fs16px);
    flex-grow: 1;
}

.add_document_field>input,
.add_document_field>select {
    flex-grow: 1;
}

.add_document_field>input {
    background: #F2F2F2;
    border-radius: 4px;
    padding: 13px 0px 13px 16px;
    border: none;
}

.add_document_field>input.attachment_name {
    background: none;
    border-bottom: 1px solid #999;
    font-size: var(--fs16px);
    border-radius: 0;
}

.select_container {
    border-bottom: 1px solid #999;
    font-size: var(--fs16px);
    flex-grow: 1;
    width: 100%;
}

.selected {
    color: var(--primaryGreyFont);
}

.placeholder {
    color: var(--secondaryGreyFont);
}

.select {
    justify-content: space-between;
    padding: 4px 8px 4px 4px;
    cursor: pointer;
    font-size: var(--fs16px);
}

.select_options {
    gap: 3px;
    padding: 16px 0 16px 0;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    max-height: 60%;
    z-index: 5 !important;
    overflow: scroll !important;
    /* trying to address blurry dropdown option text esp in chrome */
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-25%) translateY(-25%);
    -moz-transform: translateX(-25%) translateY(-25%);
    -ms-transform: translateX(-25%) translateY(-25%);
    transform: translateX(-25%) translateY(-25%);
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

.select_options::-webkit-scrollbar {
    display: none;
}

.select_options>div {
    color: var(--primaryGreyFont);
    padding: 4px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
}

.select_options>div:hover {
    background-color: #f2f2f2;
}

.disabled{
    pointer-events: none;
      background: #F2F2F2;
      color: #666 !important;
      border-bottom: 0;
      border-radius: 4px;
      border: 0 !important;
  }
@media screen and (max-width: 1180px) {
    .filter_pill_container {   
        gap:15px;      
    }
    .is_desktop .search_keyword_container {
        flex-basis:80%;
        flex-grow:unset;
        height: 48px;
    }
   
}
@media screen and (max-width: 1023px) {
    .search_keyword_container{
        height: 48px;
    }
}
@media screen and (max-width: 767px) {
    .accordion_title {
        gap:10px;
    }
    .pagination_left .show_all{
        margin-bottom: 0;
    }
    .pagination_left{
        flex-direction: column;
    }
    .result_count{
        display: block;
    }
    .data_point > div {
        padding: 8px 0px;
    }
    .filter_pill_container {   
        gap:10px;      
    }
}