.tab{
    font-size: 20px;
}

.tabItem {
    padding-bottom: 4px;
    margin-bottom: 10px;   
    font-weight: bold;
    color: #808080;
    cursor: pointer;
    border-bottom: solid var(--primaryGrey) 2px;
    position: relative;
    flex-grow: 0 !important;
    text-decoration: none;
}
.marginRight36 {
    margin-right: 36px;
}
.tabItem.activeTab {
    border-bottom: solid #3C5D69 2px;
    color: black;
    transition: border-bottom 150ms ease;
}

@media only screen and (max-width: 768px) {
    .tab{
        font-size: 12px;
    }
    .marginRight36 {
        margin-right: 28px;
    }
}