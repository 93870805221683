.header {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.header.desktop {
	flex-direction: row;
}

.header > div:first-child {
	border: 1px solid lightgray;
	padding: 15px;
	border-radius: 6px;
}

.KPITabs {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.KPITabs > div {
	cursor: pointer;
	white-space: nowrap;
}

.KPITabs > .selected {
	/* font-size: 20px; */
	font-weight: bold;
	border-bottom: 1px solid black;
}

.KPITabs > .unselected {
	color: gray;
	font-weight: bold;
}

.KPIsContainer {
	display: flex;
	flex-direction: column;
	gap: 15px;
	font-weight: bold;
	font-size: 14px;
}

.linkout {
	background-color: lightgray;
	width: 16px;
	padding: 2px;
	border-radius: 8px;
}

.linkTitle {
	font-weight: bold;
	display: flex;
	align-items: center;
	gap: 5px;
}

.link {
	text-decoration: none !important;
	color: black;
}

.powerAppsContainer {
	margin-top: 20px;
	border: 1px solid lightgray;
}

.powerAppsContainer,
.powerAppsContainer iframe {
	width: 100%;
	height: 680px;
	overflow: hidden;
}