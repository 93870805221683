/* @value calendar: "../../../../assets/calendar_icon.svg"; */
.form_field_row {
  display: block;
  margin-bottom: 30px;
}

.input input {
  padding: 6px 16px;
  width: 100%;
  height: 32px;

  /* Shades/White */
  background: #FFFFFF;
  /* Neutral/Gray 40 */
  border: 1px solid #999999;
  border-radius: 4px;
}

.input input:focus-visible {
  outline: none;
}

.small {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.input {
  width: 100%;
}

.input input:disabled {
  border: 1px solid #f2f2f2;
  background-color: #F2F2F2;
  border-radius: 4px;
}

.cursor_pointer {
  cursor: pointer;
}

.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}

.input_label {
  flex-basis: 100%;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
}

.form_group {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 100%;
}

.form_group input {
  flex-basis: 100%;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
}

.errorField > div {
  background-color: #FEE2E2;
  color: #991B1B;
}

.error_text >  div {
  color: #991B1B;
}

@media screen and (max-width:1023px) {
  .form_field_row {
    flex-wrap: wrap;
  }

  .form_group input {
    height: 48px;
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }

  .input_label {
    flex-basis: 100%;
  }

  .input {
    height: 48px;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    outline: none;
  }

  .input input:focus-visible {
    outline: none;
  }

}