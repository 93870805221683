.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.qrcolumn {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

.pagination {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.pagination .pagination_left {
  gap: 16px;
  align-items: center;
}
.is_mobile .pagination *:nth-child(2) {
  margin-top: -20px;
}
.pagination .page_numbers {
  align-items: center;
}
.pagination .page_numbers .page_number {
  color: #3c5d69;
  margin: 0 4px;
  padding: 4px 9px;
  cursor: pointer;
}
.pagination .page_numbers .page_number.active {
  font-weight: 700;
  background: #e6ecee;
  border-radius: 4px;
}

.filter_tooltip_content {
	padding: 24px;
	gap: 24px;
	height: 50vh;
	width: 288px;
	margin: 0px;
	overflow: scroll;
}

.filter_pill_container {
    display:flex;
    flex-direction:row;
    gap:24px;
    flex-wrap: wrap;
}
.filter_pill {
    padding:4px 12px;
    color:#000;
    background:#CDD9DD;
    font-weight:normal;
    display:flex;
    flex-direction:row;
    gap:10px;
    align-items:center;
    white-space: nowrap;
}

.filter_tooltip_field > label {
  white-space: nowrap;
  font-weight: bold;
}
.filter_tooltip_field > select {
  flex-grow: 1;
  padding: 12px 8px 12px 16px;
}

.flex_row_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search_keyword_container {
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 7px;
  gap: 8px;
  align-items: center;
  width: 239px;
}

.search_keyword {
  border: none;
  outline: none;
  line-height: 28px;
  width: 100%;
}
.filterbutton {
  position: relative;
  margin-left: auto;
}
.options_left {
  gap: 10px;
  /* display: flex; */
  /* flex-flow: column; */
}

.filter_tooltip_field > label {
  white-space: nowrap;
  font-weight:bold;
}
.filter_tooltip_field > select {
  flex-grow:1;
  padding: 12px 8px 12px 16px;
}

.filter_button {
  width: 132px;
  height: 36px;
  border: 1px solid #3c5d69;
  border-radius: 96px;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #3c5d69;
  font-weight: 700;
  gap: 8px;
}

.accordion {
  display: table;
  width: 100%;
}

.accordion > div {
  font-size: 12px;
}

.accordion.tableisTablet_row > div {
  display: table-row;
}

.accordion:not(.isTablet) > div {
  margin-top: 16px;
  margin-bottom: 16px;
}

.accordion > div[title] > *,
.accordion > div[title]::before {
  padding: 11px 24px;
}

.accordion.isTablet > div[title] > *,
.accordion.isTablet > div[title]::before {
  border-bottom: 1px solid lightgray;
}

.accordion > div[title] > * {
  display: table-cell;
}

.accordion > div[title]::before {
  content: attr(title);
  font-weight: 700;
}

.accordion > div:not([title]) > div {
  padding-left: 24px;
}

.checkbox {
	display: flex;
}

.checkboxWrapper {
	display: block;
}

.checkboxRow {
	display: block;
	margin-bottom: 10px;
}

.checkboxRow:last-child {
	margin-bottom: 0
}

.checkboxWrapper label {
	text-transform: capitalize;
}

.checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	height: 16px;
	width: 16px;
	border-radius: 2.67px;
	border: 0.666667px solid #999999;
	background-color: white;
	position: relative;
	cursor: pointer;
}

.checkmark:hover {
	background-color: #ccc;
}

.checkbox input:checked~.checkmark {
	background-color: white;
	border: 0.666667px solid #3C5D69;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox input:checked~.checkmark:after {
	display: flex;
}

.checkbox .checkmark:after {
	left: 1px;
	top: 1px;
	width: 11.5px;
	height: 11.5px;
	background-color: #3C5D69;
	border-radius: 1.33333px;
}

.checkmark_container {
	flex-basis: 57px;
	align-items: center;
}

.radioButtonGroup {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.radioButtonGroup label {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.radioButtonGroup input {
	margin: 0;
}

.filterDates {
  display: flex;
  justify-content: space-between;
}

.indicator {
  display: flex;
  align-items: center;
}
.indicator:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #dc6914;
  margin-right: 10px;
}

.progress:before {
  background-color: #dc6914;
}
.closed:before {
  background-color: #5e8800;
}

.userCreatedModalFooter {
	justify-content: center;
}

@media screen and (max-width: 767px) {
	.options_left {
		gap: 10px;
		display: flex;
		flex-flow: column;
	  }
  .filterbutton {
    margin-left: unset;
  }
  .pagination_left{
	flex-direction: column;
  }
}
