.tbl {
  display: table;
  width: 100%;
}

.tbl .tblCell {
  display: table-cell;
  vertical-align: top;
  min-width: 276px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.tbl .tblCell:first-child {
  width: 296px;

}

.tbl .tblCell:nth-child(2) {
  width: 296px;

}

.tbl:last-child .tblCell:first-child {
  padding-bottom: 0px;
}

.tbl:last-child .tblCell:nth-child(2) {
  padding-bottom: 0px;
}

.tblCol1 {
  display: table;
  width: 100%;
}

.tblCol1 .tblCell {
  display: table-cell;
  width: 100%;
}

.plName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.plName h2 {
  font-size: 20px;
  font-weight: bold;
}

.table_text {
  color: #567783;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px;
  cursor: pointer;
}

.map {
  background-color: grey;
  height: 215px;
  width: 272px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.font14 {
  font-size: 0.875rem;
  line-height: 19.5px;
}

.iconWrapper {
  margin-right: 8px;
  background-color: var(--greyButton);
  border-radius: 24px;
  padding: 3px 3px 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  width: 23px;
  height: 23px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

@media screen and (min-width: 1600px) {
  .tbl .tblCell:nth-child(2) {
    width: 27%;
  
  }
}

@media screen and (max-width: 1566px) {

  .tbl .tblCell {
    min-width: auto; 
  }
}
@media screen and (max-width: 1260px) {
    
  .marginBottom24 {
    margin-bottom: 18px;
  }
  .tbl .tblCell {
    min-width: auto;
    padding-right: 0;

  }
  .map {
    background-color: grey;
    height: 200px;
    width: 222px;
  }
  .tbl .tblCell:first-child {
    width: 238px;
    padding-right: 16px;
    padding-bottom: 24px;
  }
  .tbl .tblCell:nth-child(2) {
    width: 222px;
    padding-right: 0;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .map {
    background-color: grey;
    height: 200px;
    width: 100%;
    padding-top: 70%;
  }
  .tbl:first-child .tblCell:first-child {
    width: 50%;
  }
  .tbl:first-child .tblCell:nth-child(2) {
    width: 50%;
  }
  .half {
    display: block;
  }
  .half .tblCell {
    width: 50%;
    display: inline-block;
    padding-bottom: 24px;
  }
  .half .tblCell:first-child {
    width: 50%;
  }
  .half .tblCell:nth-child(2) {
    width: 50%;
  }
  .half .tblCell:last-child {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .tbl .tblCell { 
    padding-right: 0; 
    padding-bottom: 0; 
  }
  .tbl .tblCell:nth-child(2),
  .tbl .tblCell:first-child {
    width: 100%; 
    padding-right: 0; 
    padding-bottom: 0; 
  }
  .marginBottom24 {
    margin-bottom: 24px;
  }
  .map {
    width: 270px;
    height: 200px;
    padding-top: 0;
  }
  .tbl:first-child .tblCell:first-child {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 24px;
  }
  .tbl:first-child .tblCell:nth-child(2) {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .tbl {
    display: block;
  }
  .tbl .tblCell {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .plName {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 26px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
  }
  .plName h2 {
    padding-bottom: 10px;
  }
  .plName img {
    margin-left: 0 !important;
  }
}
