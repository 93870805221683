.index {
    color: #3c5d69;
    margin: 0 2px;
    cursor: pointer;
    background: transparent;
    display: flex;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
}

.active{
    background: #E6ECEE;
}