
.toggleswitch {
  position: relative;
  width: 18.75em;
  display: inline-block;
  text-align: left;
  top: 2em;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 5em;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 9em;
  padding: 0;
  line-height: 9em;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 2.5em;
  background-color: #3C5D69;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right:2.5em;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 6em;
  margin: 1.25em;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10em;
  border: 0 solid #bbb;
  border-radius: 5em;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0em;
}
