.errorCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  padding: 14px 18px;
  margin-bottom: 24px;
}

.link {
  color: #991b1b;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
}

.link:hover {
  text-decoration: none;
}

.danger {
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.danger .link {
  color: #991b1b;
}

.warning {
  background-color: #fef3c7;
  border: 1px solid #fde68a;
  color: #92400e;
}
.warning .link {
  color: #92400e;
}

.success {
  background: #d1fae5;
  border: 1px solid #a7f3d0;
  border-radius: 8px;
  color: #065f46;
}
.success .link {
  color: #065f46;
}
.info {
  background: #cbf1ff;
  border: 1px solid #bbdeeb;
  border-radius: 8px;
  color: #00749e;
}

.info .link {
  color: #00749e;
}

.errorMsg {
  margin-right: 8px;
  text-wrap: wrap;
}

.errorCardInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon {
  width: 32px !important;
  height: 32px !important;
  margin-right: 20px;
}

/* .icon img {
  max-width: 100%;
} */
