.nameplate-card {
  padding: 24px;
  margin-bottom: 80px;
}
.back-to {
  margin-bottom: 40px;
}
.go-back {
  cursor: pointer;
  color: var(--darkBlueButton);
  font-weight: bold;
  font-size: 14px;
}
.nameplate-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.nameplate-title {
  font-size: 20px;
  font-weight: bold;
}
.nameplate-tile {
  width: 302px;
}
.nameplate-content {
  height: 358px;
  width: 284px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column nowrap;
}

.nameplate-ellipse {
  height: 36px;
  align-items: end;
  justify-content: center;
  position: relative;
}

.nameplate-img {
  width: 282px;
  height: 245px;
}

.nameplate-text-container {
  margin: 8px;
  font-size: 14px;
}

.ellipsis {
  height: 22px;
  margin-right: 18px;
  cursor: pointer;
}
.grey_font {
  font-size: 12px;
  color: #808080;
}
.back-arrow {
  filter: var(--svgFilterDarkBlueButton);
  transform: rotateY(180deg);
  margin-top: 1px;
}
.pagination-left-arrow {
  filter: var(--svgFilterGrey);
  transform: rotateY(180deg);
  cursor: pointer;
}
.pagination-right-arrow {
  filter: var(--svgFilterGrey);
  cursor: pointer;
}
.index {
  color: #3c5d69;
  margin: 0 14px;
  cursor: pointer;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
  }
  div.page-title { 
    width: 100%;  
    font-size: 16px;    
    opacity: 1;
    margin-bottom: 20px;
 
  }
}

@media print {
  @page {
      size: A4;
      margin: 16mm 10mm 10mm 10mm;
  }

  .watermark {
      display: block;
      counter-increment: page;
      position: fixed;
  }

  div.page-footer, div.footer-info {
      display: block;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}



@media screen and (max-width:1023px){
  .nameplate-dropdown .input-box,
  .nameplate-dropdown #selectDropdown{
    height: 48px;
    margin: 0;
    
  }
}
@media screen and (max-width:767px){
  .nameplate-card {
    padding: 24px 15px;
    /* margin-bottom: 80px; */
}
  .nameplate-dropdown,
  .nameplate-dropdown .selectDropdownWrp,
  .nameplate-dropdown .input-box,
  .nameplate-dropdown #selectDropdown{
 
    width: 100%;
  }
}