.DatePickerCalendar__row {
    display: flex;
}

.DatePickerCalendar__header {
    margin-bottom: 8px;
    display: flex;
}

.DatePickerCalendar__cell {
    padding: 4px;
    width: 38px;
    height: 38px;
    font-weight: 700;
    margin: 0 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DatePickerCalendar__dayCell {
    cursor: pointer;
    font-weight: 400;
}

.DatePickerCalendar__dayCell:not(.DatePickerCalendar__dayCell_selected):hover {
  background-color: #e9e9e9;
}

.DatePickerCalendar__dayCell:active {
  background-color: #d1d1d1;
}

.DatePickerCalendar__dayCell_today {
  background: #CDD9DD;
  transition: background-color 250ms ease-in;
}

.DatePickerCalendar__dayCell_selected {
  background-color: #3C5D69 !important;
  transition: background-color 250ms ease-in;
  color: #fff;
}

.DatePickerCalendar__dayCell_selected:hover {
  background-color: #223F4B !important;
  transition: background-color 250ms ease-in;
  color: #fff;
}



.weekContainer,
.dayContainer {
  display: flex;
  flex-wrap: wrap;
}

.weekCell,
.dayCell {
  flex: 0 0 calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.dayCell--faded {
  opacity: .4;
}

.dayCell--today {
  background-color: #CDD9DD;
}

.dayCellSelectedRange {
  background-color: #3C5D69;
  color: #fff;
}

.dayCell:hover {
  cursor: pointer;
  font-weight: 400;
}

.dayCell:not(.dayCell_selected):hover {
  background-color: #e9e9e9;
}

.dayCell:active {
  background-color: #d1d1d1;
}

.dayCell_selected {
  /* background: #CDD9DD; */
  background-color: #3C5D69;
  color: #fff;
}