.homecardloader-container {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 180px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.homecardloader-container .homecardloader-back {
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(88.69deg, rgba(205, 217, 221, 0.6) 0%, rgba(205, 217, 221, 0.3) 100%);
  min-height: 180px;
}

.homecardloader-container .homecardloader-back .homecardloader-spinner {
  display: block; 
  width: 100%; 
  height: 100%; 
  background-size: 48px 48px;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 180px;
}

.homecardloader-container .homecardloader-top-content {
  width: 144px;
  height: 24px;
  background-color: #B4C6CC;
  position: absolute;
  border-radius: 100px; 
  top: 24px;
  left: 24px; 
  z-index: 2;
}

.homecardloader-container .homecardloader-bottom-container {
  right: 24px;
  height: 48px;
  background-color: #CDD9DD;
  position: absolute; 
  border-radius: 100px; 
  bottom: 24px; 
  left: 24px; 
  z-index: 2;
  display: flex;
}

.homecardloader-container .homecardloader-bottom-content {
  width: 96px;
  height: 16px;
  background-color: #B4C6CC;
  border-radius: 100px; 
  margin: auto;
}