.card-county {
  /* -webkit-box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1); */
  margin-bottom: 40px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 24px 24px 24px;
  /* flex-flow: row nowrap;
  justify-content: space-between;
  padding: 24px;
  height: fit-content; */
}

.plant-detail {
  box-shadow: 0px 25px 30px -12px rgb(0 0 0 / 10%);
}

.county {
  font-size: var(--fs24px);
  font-weight: bold;
}

.w-88vw {
  width: 88vw;
}

.pad-bottom16 {
  padding-bottom: 1rem;
}

.pad-bottom24 {
  padding-bottom: 1.5rem;
}

.pad-bottom32 {
  padding-bottom: 2rem;
}

.plant-load-mw {
  font-weight: bold;
  padding-bottom: 1.5rem;
}

.numeric-value {
  font-size: var(--fs40px);
}

.numeric-value.value-type::after {
  content: "MW";
  font-size: var(--fs12px);
  position: relative;
  top: -5px;
  left: 8px;
  vertical-align: super;
}
.card__ambient_temp_wrp {
  padding-bottom: 24px;
}

/* .numeric-identifier {
  font-size: var(--fs12px);
} */
.row-to-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;


  flex-flow: column nowrap;
  justify-content: flex-start;
}

.align-item-center {
  align-items: center;
}

.col-to-row {
  display: flex;
  flex-flow: row nowrap;
}
.justify-between {
  justify-content: space-between;
}

.config-details .row-to-col {
  flex-basis: 19%;
  margin: 0 5px;
}

.table .row-to-col {
  flex-basis: 10%;
}


.temp-container {
  font-size: 0.875rem;
}

.temp::after {
  content: "\00b0";
}

.col-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.pad-bottom24 .error_state {
  color: var(--errorRed);
  font-size: 14px;
  content: '';
  font-weight: 400;
}

.error_state .numeric-value,
.error {
  color: var(--errorRed);
  font-size: 14px;
  content: '';
  font-weight: 400;
}

.error_state .col-item-title {
  color: var(--errorRed);
}

.error_state .error {
  padding-bottom: 1.5rem;
}

.padd5 {
  padding-right: 5px;
}

.date {
  font-size: 0.875rem;
}

.lpo.date {
  padding-bottom: 24px;
}

.plant-detail .location {

  flex-basis: 18%;
  justify-content: space-between
}

.address {
  padding-bottom: 24px;
}

.header {
  padding-bottom: 5px;
  font-size: 14px;
}

.address-text {
  font-size: 14px;
}

.psdflex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.psdflex-row:nth-child(1) {
  padding-bottom: 44px;
}

.address-icon-wrapper {
  margin-right: 8px;
  border-radius: 24px;
  padding: 3px 0px 1px;
  box-shadow: none;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}

.opt-icon-wrapper {
  margin-right: 8px;
  border-radius: 24px;
  padding: 0px 3px 2px;
  box-shadow: none;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}

.icon-wrapper {
  margin-right: 8px;
  background-color: var(--greyButton);
  border-radius: 24px;
  padding: 3px 3px 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in, -webkit-box-shadow 250ms ease-in;
}

.icon-wrapper:hover {
  background-color: #B4C6CC;
  -webkit-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  -webkit-transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, -webkit-box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in, -webkit-box-shadow 250ms ease-in;
}

.address-icon-wrapper {
  margin-right: 8px;
  border-radius: 24px;
  padding: 3px 0px 1px;
  box-shadow: none;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}

.opt-icon-wrapper {
  margin-right: 8px;
  border-radius: 24px;
  padding: 0px 3px 2px;
  box-shadow: none;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}


.plt-icon {
  height: 17px;
}

.opt-icon {
  height: 20px;
}

.card__label_result_wrp strong {
  margin-bottom: 5px;
}




@media screen and (max-width: 1400px) {

  .config-section-wrapper .config-section {
    margin-bottom: 30px;
    flex-basis: 31%;
  }

  .config-section-wrapper {

    flex-wrap: wrap;

  }

  .plant-detail.plant-details-main .location {
    flex-basis: 20%;
  }

  .plant-detail.plant-details-main .marginRight48 {
    margin-right: 0px;
  }

  .plant-details-main .location {
    margin-bottom: 24px;
  }
}


@media screen and (max-width: 1180px) {
  .config-section-wrapper .config-details-cta .primaryBtn {
    width: 276px;
  }

  .plant-detail.plant-details-main .plant-load-mw {
    margin-bottom: 24px;
  }

  .config-details-cta {
    display: block;
    margin-bottom: 24px;
  }

}

@media screen and (max-width: 1023px) {

  .plant-detail .primaryBtn {
    padding: 1rem 2rem;
  }

  .plant-detail .primaryBtn.showMoreBtn {
    margin-top: 16px;
    width: 100%;
    padding: 0.8rem 2rem;
  }

  .plant-load-mw {
    font-weight: bold;
    padding-bottom: 0;
  }

  .login-header {
    font-size: var(--fs28px);
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {

  .plant-location img {
    margin-left: 0 !important;
  }

  .plant-location #myBtn {
    margin-top: 10px;
    display: block;

  }

  .card-county {

    padding: 24px 16px;
  }

  .google-map.map {
    background-color: grey;
    height: 215px;
    width: 100%;
  }

  .config-details-cta .primaryBtn {
    width: 100%;
  }

  .config-details-cta .height36 {
    height: auto;
  }

  .config-details-cta .mwidth-206 {
    max-width: 100%;
  }

  .config-section-wrapper .config-section {
    margin-bottom: 30px;
    flex-basis: 100%;
  }

  .config-section-wrapper .config-section:last-child {
    margin-bottom: 0px;
    flex-basis: 100%;
  }

  .plant-detail.plant-details-main .location {
    flex-basis: 100%;
    margin: 0;
    order: 0;
    margin-bottom: 30px;
  }

  .col-to-row {
    flex-wrap: wrap;

  }

  .row-to-col {
    width: 100%;
    order: 1;
  }

  .card-county {
    display: block;
    margin-bottom: 24px;
  }

  .psdflex-row:nth-child(1) {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 400px) {
  .card-county {
    display: block;
    padding: 15px;
    margin-bottom: 16px;
  }

  .psdflex-row:nth-child(1) {
    padding-bottom: 24px;
  }

}

.disabled {
  pointer-events: none;
  font-weight: normal;
}

.disabled #selectDropdown {
  pointer-events: none;
  font-weight: normal;
}

.plant-location {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

.map {
  background-color: grey;
  height: 215px;
  width: 272px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.modal-map .map {
  width: 145px;
  height: 116px;
}

hr {
  width: 100%;
  color: #E5E5E5;
  border: none;
  border-top: 1px solid;
  margin: 33px 0;
}

.plant-info-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.config-header {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
}

.config-details {
  background-color: #F2F2F2;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  position: relative;
}

.config-details .pencil {
  position: absolute;
  top: 15px;
  right: 15px;
}

.table {
  margin-bottom: 16px;
  /* overflow: hidden; */
  transition: height 150ms ease-in;
}

.table-header {
  font-weight: bold;
  font-size: 14px;
}

.table-columns {
  background-color: #F2F2F2;
  border-radius: 4px;
  padding: 24px;
}

.tableRow {
  border-bottom: 1px solid #E6E6E6;
  padding: 9.5px 24px;
  /* visibility: hidden; */
}

.tableRow.active-row {
  border-bottom: 1px solid #E6E6E6;
  padding: 9.5px 24px;
  visibility: visible;
}

.row-data {
  font-size: 14px;
  margin-right: 10px;
}