.tooltipDropdown {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #4D4D4D;
  pointer-events: none;
  padding: 12px;
  z-index: 99999;
  width:200px;
  display: inline-block;
  position: fixed;
  transition: opacity, transform 0.06s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: opacity, transform 0.06s cubic-bezier(0, 0, 0.2, 1);
  -moz-transition: opacity, transform 0.06s cubic-bezier(0, 0, 0.2, 1);
  -ms-transition: opacity, transform 0.06s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: opacity, transform 0.06s cubic-bezier(0, 0, 0.2, 1);
  background: #FFFFFF;
  /*shadow-def*/box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
