.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.qrcolumn {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

.filter_pill_container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
}

.filter_pill {
  padding: 4px 12px;
  color: #000;
  background: #cdd9dd;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
}

.report_table {
  border-collapse:collapse;
  font-size: 12px;
}
.report_table th {
  background: #F2F2F2;
}

.report_table th, .report_table td {
  padding:12px;
}
.report_table .unread td {
  background: rgba(0, 116, 158, 0.05)
}
.report_table .unread .document_name,
.unread .report_table_accordion .more_actions {
  font-weight:bold;
}
.report_table.report_table_accordion th {
  background:none;
  padding-left:15px;
}
.report_table.report_table_accordion tr + tr > * {
  border-top:1px solid #E6E6E6;
}
.report_table_accordion {
  width:100%;
}
.unread .report_table_accordion tr > * {
  background: rgba(0, 116, 158, 0.05)
}
.result_container {
  gap:24px;
  padding: 24px 0 0;
  align-items:flex-start;
}

.accordion_title {
  gap:40px;
  flex-grow:1;
  padding:14px 0 14px 12px;
  color:#00749E;
}
.accordion_title.unread {
  font-weight:bold;
}
.data_point {
  align-self:stretch;
}
.is_tablet .data_point + .data_point {
  border-top: 1px solid #E6E6E6;
}
.data_point > div {
  padding: 8px 24px
}

.is_tablet .data_point > div {
  flex-basis:50%;
}

.is_desktop .data_point > div {
  padding:0;
}
@media screen and (max-width: 1023px) {
  .report_table th {
    text-align:left;
    background: #F2F2F2;
    width: 40%;
  }
}
@media screen and (max-width: 767px) {
  .accordion_title {
    gap:10px;
  }
  .data_point > div {
    padding: 8px 0px;
}
}
.issue_reports_container_outlet {
  align-items: flex-start;
  padding: 24px 24px 40px;
  gap: 24px;
}

.pagination {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.pagination .pagination_left {
  gap: 16px;
  align-items: center;
}

.is_mobile .pagination *:nth-child(2) {
  margin-top: -20px;
}

.pagination .page_numbers {
  align-items: center;
}

.pagination .page_numbers .page_number {
  color: #3c5d69;
  margin: 0 4px;
  padding: 4px 9px;
  cursor: pointer;
}

.pagination .page_numbers .page_number.active {
  font-weight: 700;
  background: #e6ecee;
  border-radius: 4px;
}

.issue_reports_container {
  flex-grow: 1;
}

.issue_reports_title {
  margin-bottom: 10px;
  font-family: "DIN Next LT Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.filter_tooltip_content {
  padding: 24px;
  gap: 24px;
  height: 50vh;
  width: 288px;
  margin: 0px;
  overflow: scroll;
}

.filter_tooltip_content::-webkit-scrollbar {
  display: none;
}

.filter_tooltip_field>label {
  white-space: nowrap;
  font-weight: bold;
}

.filter_tooltip_field>select {
  flex-grow: 1;
  padding: 12px 8px 12px 16px;
}

.quote_request_container_outlet {
  align-items: flex-start;
  padding: 24px 24px 40px;
  gap: 24px;
}

.quote_request_container {
  flex-grow: 1;
}

.quote_request_title {
  margin-bottom: 10px;
  font-family: "DIN Next LT Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.table_icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.flex_row_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search_keyword_container {
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 7px;
  gap: 8px;
  align-items: center;
  width: 239px;
}

.search_keyword {
  border: none;
  outline: none;
  line-height: 28px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .search_keyword_container {
    border: 1px solid #999;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 7px;
    gap: 8px;
    align-items: center;
    width: 100%;
  }
}

.filterbutton {
  position: relative;
  margin-left: auto;
}

.options_left {
  gap: 10px;
  /* display: flex; */
  /* flex-flow: column; */
}

.filter_button {
  width: 132px;
  height: 36px;
  border: 1px solid #3c5d69;
  border-radius: 96px;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #3c5d69;
  font-weight: 700;
  gap: 8px;
}

.accordion {
	display: table;
	width: 100%;
  table-layout: auto;
}

.accordion>div {
	font-size: 12px;
}

.accordion.tableisTablet_row>div {
	display: table-row;
}

.accordion:not(.isTablet)>div {
	margin-top: 16px;
	margin-bottom: 16px;
}

.accordion>div[title]>*,
.accordion>div[title]::before {
	padding: 11px 24px;
}

.accordion.isTablet>div[title] {
	display: table-row;
}

.accordion.isTablet>div[title]>*,
.accordion.isTablet>div[title]::before {
  padding-left: 10px;
	display: table-cell;
	border-bottom: 1px solid lightgray;
}

.accordion.isTablet>div>.moreActions{
  padding: 11px 0 11px 10px;
}

.accordion>div[title]>* {
	display: table-cell;
}

.accordion>div[title]::before {
	content: attr(title);
	font-weight: 700;
}

.accordion>div:not([title])>div {
	padding-left: 24px;
}

.table {
  display: table;
  width: 100%;
}

/* Header */
.table>div:first-child {
  display: table-header-group;
  font-weight: 700;
}

/* Header-Cell */
.table>div:first-child>div>div {
  background: #f2f2f2;
}

/* Body */
.table>div:nth-child(2) {
  display: table-row-group;
}

.table>div>div {
  display: table-row;
}

/* Cell */
.table>div>div>div {
  padding: 12px;
  height: 48px;
  display: table-cell;
  border-bottom: 1px solid #f2f2f2 !important;
  font-size: 14px;
}

.filterDates {
  display: flex;
  justify-content: space-between;
}

.indicator {
  display: flex;
  align-items: center;
}

.indicator:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #dc6914;
  margin-right: 10px;
}

.progress:before {
  background-color: #dc6914;
}

.closed:before {
  background-color: #5e8800;
}

@media screen and (max-width: 767px) {
  .options_left {
    gap: 20px;
    display: flex;
    flex-flow: column;
  }

  .filterbutton {
    margin-left: unset;
  }

  .pagination_left {
    flex-direction: column;
  }
}