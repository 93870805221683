.panel_tbl{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #E6E6E6;
  position: relative;

}

.avatarDefault{
  width:45px;
  height:45px;
  background-color: #999999;
  color:#fff;
  font-size: 16px;font-weight: bold;
  text-transform: uppercase;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.panel:not(:last-child){
  margin-bottom: 15px;
}
.panel_tbl_cell{
  display: table-cell;
  /* margin-right: 10px; */
  line-height: 1.5;
  color: #000;
  flex-basis: 32.66%;
}

.profiledetails{
  display: flex;
  align-items: center;
 }

 .avatarWrap{
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactname{
  font-size: 14px; 
  text-transform: capitalize;
}
.jobtitle{
  font-size: 14px; 
  text-transform: capitalize;
}

.contactBox>* {
  flex: 0 0 50%;
}
@media screen and (max-width :1023px){
  .panel_tbl{
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(50% - 24px);
    margin: 0;
    min-height: 150px;
  }
  .panel_tbl_cell{
    display:block;
  }
  .editAction{
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
@media screen and (max-width :767px){
  
  .profiledetails{
    margin-bottom: 12px;
   }
  .panel_tbl{
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: auto;
    margin: 0;
    padding: 16px 0;
  }
  
}