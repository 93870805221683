.flex_column {
	display: flex;
	flex-direction: column;
}

.flex_row {
	display: flex;
	flex-direction: row;
}

.issue_reports_container_outlet {
	align-items: flex-start;
	padding: 24px 24px 40px;
	gap: 24px;
}

.issue_reports_container {
	flex-grow: 1;
}

.issue_reports_title {
	/* height: 34px; */
	margin-bottom: 10px;
	font-family: 'DIN Next LT Pro';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;
	color: #000000;
}

.filter_tooltip_content {
	padding: 24px;
	gap: 24px;
	height: 50vh;
	width: 288px;
	margin: 0px;
	overflow: scroll;
}

.filter_pill_container {
    display:flex;
    flex-direction:row;
    gap:24px;
    flex-wrap: wrap;
}
.filter_pill {
    padding:4px 12px;
    color:#000;
    background:#CDD9DD;
    font-weight:normal;
    display:flex;
    flex-direction:row;
    gap:10px;
    align-items:center;
    white-space: nowrap;
}

.filter_tooltip_field > label {
    white-space: nowrap;
    font-weight:bold;
}
.filter_tooltip_field > select {
    flex-grow:1;
    padding: 12px 8px 12px 16px;
}
.select_container {
    position:relative;
}
.select_class {
    padding: 4px 8px 4px 16px;
    justify-content: space-between;
    display:flex;
    flex-direction:row;
    align-items:center;
    border-radius:4px;
    border:1px solid #999;
    font-size:14px;
}
.select_class.empty_value {
    color:#999;
}
.issueReportcolumn{
	width: 100%;
	display: flex;
	flex-flow: column;
	gap: 20px;
}

.select_class:focus {
    border-color:#3C5D69;
    color:#000;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
}
.select_class + .select_options_class {
    background:#fff;
    width:100%;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size:var(--fs14px);
}
.select_class + .select_options_class > div {
    padding:14px 24px;
    cursor:pointer;
}
.select_class.select_disabled {
    background-color:#F2F2F2;
    color:#666;
    border-color:#F2F2F2;
}

.filter_tooltip_content .short {
	height: unset;
}

.filter_tooltip_content::-webkit-scrollbar {
	display: none;
}

.filter_tooltip_field {
	gap: 8px;
}

.filter_tooltip_field>label {
	white-space: nowrap;
	font-weight: bold;
}

.table_icon {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	margin-right: 4px;
}

.flex_row_space_between {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.flex_row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.search_keyword_container {
	border: 1px solid #999;
	border-radius: 4px;
	padding-left: 4px;
	padding-right: 7px;
	gap: 8px;
	align-items: center;
	width: 239px;
}

.search_keyword {
	border: none;
	outline: none;
	line-height: 28px;
	width: 100%;
}

.options_left {
	gap: 16px;
}

.filter_button {
	width: 132px;
	height: 36px;
	border: 1px solid #3C5D69;
	border-radius: 96px;
	background-color: white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #3C5D69;
	font-weight: 700;
	gap: 8px;
}

.accordion {
	display: table;
	width: 100%;
  table-layout: auto;
}

.accordion>div {
	font-size: 12px;
}

.accordion.tableisTablet_row>div {
	display: table-row;
}

.accordion:not(.isTablet)>div {
	margin-top: 16px;
	margin-bottom: 16px;
}

.accordion>div[title]>*,
.accordion>div[title]::before {
	padding: 11px 24px;
}

.accordion.isTablet>div[title] {
	display: table-row;
}

.accordion.isTablet>div[title]>*,
.accordion.isTablet>div[title]::before {
  padding-left: 10px;
	display: table-cell;
	border-bottom: 1px solid lightgray;
}

.accordion.isTablet>div>.moreActions{
  padding: 11px 0 11px 10px;
}

.accordion>div[title]>* {
	display: table-cell;
}

.accordion>div[title]::before {
	content: attr(title);
	font-weight: 700;
}

.accordion>div:not([title])>div {
	padding-left: 24px;
}

.filterDates {
	display: flex;
	justify-content: space-between;
}

.checkbox {
	display: flex;
}

.checkboxWrapper {
	display: block;
}

.checkboxRow {
	display: block;
	margin-bottom: 10px;
}

.checkboxRow:last-child {
	margin-bottom: 0
}

.checkboxWrapper label {
	text-transform: capitalize;
}

.checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	height: 16px;
	width: 16px;
	border-radius: 2.67px;
	border: 0.666667px solid #999999;
	background-color: white;
	position: relative;
	cursor: pointer;
}

.checkmark:hover {
	background-color: #ccc;
}

.checkbox input:checked~.checkmark {
	background-color: white;
	border: 0.666667px solid #3C5D69;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox input:checked~.checkmark:after {
	display: flex;
}

.checkbox .checkmark:after {
	left: 1px;
	top: 1px;
	width: 11.5px;
	height: 11.5px;
	background-color: #3C5D69;
	border-radius: 1.33333px;
}

.checkmark_container {
	flex-basis: 57px;
	align-items: center;
}

.radioButtonGroup {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.radioButtonGroup label {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.radioButtonGroup input {
	margin: 0;
}

.copy_link_textbox{
	width: 100%;
	margin-right: 10px;
}

.headerCopy{
	max-width: 70%;
}
.card{
    background-color: #fff;
    padding:24px;
    border-radius: 8px;
}

@media screen and (max-width:1023px){
	.headerCopy{
		max-width: 100%;
	}
}
@media screen and (max-width:767px){
	.card{
		padding: 24px 16px;
	}
}

