.card{
    background-color: #fff;
    padding: 48px 20px;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}
.related_links {
    margin: 2rem 24px 0 48px;
    display: block;
}
@media screen and (max-width: 1023px) {
    
}
@media screen and (max-width: 767px) {
    .card {
        padding: 16px
       }
}