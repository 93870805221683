.sidebar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  background-color: white;
  border-radius: 0px 8px 0px 0px;
  position: sticky;
  left: 0;
  z-index: 2;
  /* for fixed position */
}
.sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  background-color: white;
  border-radius: 0px 8px 0px 0px;
  /* margin-top: 48px; */
  /* for fixed position */
  width: 64px; 
  -webkit-transition: width 350ms ease-in-out; 
  -o-transition: width 350ms ease-in-out; 
  transition: width 350ms ease-in-out;
}
.sidebar.active {
  width: 300px;
  -webkit-transition: width 350ms ease-in-out;
  -o-transition: width 350ms ease-in-out;
  transition: width 350ms ease-in-out;
}
.menu-toggle {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  white-space: nowrap;
}
.sidebar-item.active:nth-child(1) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 24px;
}
.sidebar-item:nth-child(1) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 29%;
  -webkit-transition: margin-right 350ms;
  -o-transition: margin-right 350ms;
  transition: margin-right 350ms;
}

.sidebar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 12px 8px 12px 11px;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
}
.sidebar-item:focus-visible {
  outline: none;
}
.sub-route:focus-visible {
  outline: none;
}
.sidebar-item:hover {
  background: var(--primaryGrey);
  overflow:visible;
}

.sidebar-item.expanded:not(.has-sub-routes) {
  padding: 0;
}
.sidebar-item.expanded a {
  padding: 12px 8px 12px 11px;
  width:100%;
}

.noHover:hover {
  background: var(--darkBlueButton) !important;
}
.active-location {
  background-color: var(--darkBlueButton);
}

.active-location-icon {
  -webkit-filter:  brightness(0) invert(1);
          filter:  brightness(0) invert(1);
}

.active-location-icon:hover {
  -webkit-filter:  none;
          filter:  none;
}

.sidebar-item:nth-child(1):hover {
  background: inherit;
}
.sidebar-menu {
  width: 64px;
  -webkit-transition: width 350ms ease-in-out;
  -o-transition: width 350ms ease-in-out;
  transition: width 350ms ease-in-out;
  /* for position fixed */
  position: fixed;
  z-index: 2;
}
.sidebar-menu.active {
  width: 300px;
  -webkit-transition: width 350ms ease-in-out;
  -o-transition: width 350ms ease-in-out;
  transition: width 350ms ease-in-out;
}
.sidebar-menu-items {
  padding: 0;
  position: absolute;
  width: inherit;
  -webkit-transition:top 0s smooth;
  -o-transition:top 0s smooth;
  transition:top 0s smooth;
}

.sidebar-menu-txt {
  position:relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  opacity: 0;
  /* visibility: hidden; */
  /* left: -999px; */
  text-align: justify;
}
.sidebar-menu-txt.active {
  /* visibility: visible; */
  opacity: 1;
  /* left: 0; */
  -webkit-transition: opactiy 50ms ease-in-out;
  -o-transition: opactiy 50ms ease-in-out;
  transition: opactiy 50ms ease-in-out;
  font-weight: 700;
}

.active-text {
  color: #ffffff;
  padding-left: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.text {
  padding-left: 16px;
  color: var(--primaryGreyFont);
  font-weight: 500;
  white-space: nowrap;
}
.toggle-text {
  font-weight: bold;
  margin-left: 5px;
  color: var(--darkBlueButton);
}

.sidebar-item .sub-route {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
}

.a-close {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  width: 0;
}

.open-close {
  width: auto !important;
}

.sidebar-icon {
  pointer-events: none;
  height: 24px;
}
.flip {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* div.sub-route-wrapper {
  background: white;
  z-index: 2;
  border-radius: 0px 8px 8px 0px;
  width: 300px;
  position: absolute;
  padding: 24px;
  font-weight: bold;
  left: 66px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  transition: left 350ms ease-in-out;

  margin-top: -33px;
} */

div.sub-route-wrapper.active {
  left: 282px;
  -webkit-transition: left 350ms ease-in-out;
  -o-transition: left 350ms ease-in-out;
  transition: left 350ms ease-in-out;
}

a.sub-route {
  width: 100%;
  color: #006487;
  padding: 12px;
  margin-left: 0;
  border-radius: 4px;
}

a.sub-route:hover {
  background: var(--primaryGrey);
}
div.sidebar-parent-nonroute {
  padding: 12px;
  margin-left: 5px;
  color: var(--primaryGreyFont)
}



.sidebar-item {
  position:relative;
}
.sidebar-item .sub-route-wrapper {
  display:none;
  position:absolute;
  left:100%;
  background-color:transparent;
}

.sidebar-item .sub-route-wrapper .inner-wrapper {
  margin-left:18px;
  background: white;
  z-index: 2;
  border-radius: 0px 8px 8px 0px;
  width: 300px;
  /* position: absolute; */
  padding: 24px;
  font-weight: bold;
  /* left: 66px; */
  -webkit-box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  -webkit-transition: left 350ms ease-in-out;
  -o-transition: left 350ms ease-in-out;
  transition: left 350ms ease-in-out;
  -webkit-clip-path: inset(-100% -100% -100% 0px);
          clip-path: inset(-100% -100% -100% 0px);
}
.sidebar-item:hover .sub-route-wrapper {
  display:initial;
}