.navbar {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
}

.nav-item {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-basis: auto;
  align-items: center;
}

.nav-item:last-child {
  justify-content: flex-end;
}

.login-details {
  justify-content: flex-end;
}
.initials {
  border-radius: 24px;
  background-color: transparent;
  color: #ffffff;
  font-weight: bold;
  padding: 0;
  font-size: 16px;
  margin-right: 8px;
  width: 40px;
  overflow: hidden;
  height: 40px;
}
.initials img {
  max-width: 100%;
}
.initials-full {
  border-radius: 9999px;
  background-color: transparent;
  color: #ffffff;
  font-weight: bold;
  padding: 0;
  font-size: 16px;
  margin-right: 8px;
  overflow: hidden;
  max-width: 70px;
  max-height: 70px; 
}
.initials-full img {
  max-width: 100%;
}
.name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}
.position {
  font-size: 14px;
}
.bell-icon {
  margin-right: 24px;
}
.nav__action {
  max-width: 40px;
}
.nav__action_wrp {
  display: flex;
  align-items: center;
}

.sidebar-mobile-wrapper .input-box .search__icon {
  max-height: 16px;
}

.sidebar-mobile-wrapper .input-box .close__icon {
  max-height: 16px;
  padding: 0 10px;
}

.sidebar-mobile-wrapper .input-box .close__icon img {
  width: 16px;
}

.App-logo {
  height: 90px;
  width: 150px;
}

.sidebar-mobile-wrapper .input-box .search__icon {
  padding: 0 10px;
}
.sidebar-mobile-wrapper .input-box {
  align-items: center;
}
.mobile-nav-item {
  font-size: 14px;
}
.navbar {
  border-bottom: 1px solid #cdd9dd;
}

@media screen and (min-width: 768px) {
  .App-logo {
    height: 122px;
    width: 185px;
  }
}
@media screen and (min-width: 1024px) {
  .App-logo {
    pointer-events: none;
    height: 81px;
    width: 122px;
  }
}
