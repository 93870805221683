.notification{
  position: relative;
}

.notification__value{
  position: absolute;
  background: #E31F26;
  padding: 2px 8px;
  min-width: 30px;
  min-height: 20px;
  border-radius: 12px;
  color: #fff;
  left: 16px;
  transform: translateX(-100%);
  font-size: 0.75rem;
  text-align: center;
  top: -8px;
}
.inner_spacing_zero > div{
  margin-left: 10px;  
  min-width: 20px;
  min-height: 20px;
  padding: 2px;  
}