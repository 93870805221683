.pill {
  padding: 4px 13px;
  border-radius: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  background: #999999;
  white-space: nowrap;
}

.default {
	color: #000;
	background: #CDD9DD;
	font-weight: normal;
}

.success {
  background: #5e8800;
}

.warning {
  background: #dc6914;
}
.primary {
  background: #3c5d69;
}
.dkBlue {
  background: #006487;
}
.pillIcon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.pill.dismissible {
  display:flex;
  flex-direction: row;
  gap: 8px;
  align-items:center;
}