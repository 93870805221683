.searchItem{
    border-bottom: 1px solid #CCCCCC;
    text-decoration: none;
    display: block;
    color:#000;
}

.searchItem:focus,
.searchItem:active{
    color:#000;
}
.searchItem:last-child{
    border-bottom:0;
}
.searchItemWrapper{
    background:#fff;
    padding:0 24px;
    border-radius: 8px;
}
.paginationWrapper{
    padding:32px 0;
    align-items: center;
}

.paginationFlex {
  display: flex;
}

@media (max-width: 1023px) {
  .paginationWrapper .rowItem {
    width: 100%;
  }

  .paginationFlex {
    width: auto;
  }

  .paginationFlex > div {
    flex-wrap: nowrap;
  }
}
  
@media (max-width:767px) {
  .paginationWrapper .rowItem {
    padding-bottom: 16px;
    text-align: center;
  }
    
  .paginationFlex {
    width: 100%;
    display: block;
    text-align: center;
  }

  .paginationFlex > div {
    width: auto;
    display: inline-flex;
  }
}