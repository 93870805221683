.DateRangePicker_picker {
    z-index: 5;
}

/* these three heights need to stay in sync */
.DayPicker {
    height: 300px;
}
.DayPicker_transitionContainer__horizontal {
    height: 300px !important;
}

.DayPicker_focusRegion {
    height: 300px;
}

.CalendarDay__selected_span {    
    background: var(--primaryGrey);
    border: var(--primaryGrey);
    color: black;
    border-radius: 20%;
}

.CalendarDay__selected {
    background: var(--primaryGrey);
    border: var(--primaryGrey);
    color: black;
    border-radius: 20%;
}

.CalendarDay__selected:hover {
    background: var(--primaryGrey);
    border: none;
    color: black;
    border-radius: 20%;
}

.CalendarDay__selected_span:hover {
    background: var(--primaryGrey);
    border: none;
    color: black;
    border-radius: 20%;
}

.CalendarMonth_caption {
    background: var(--primaryGrey);
    font-size: 24px;
    padding: 3%;
    border-radius: 4%;
}

.CalendarDay {
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 7%;
}
.CalendarDay:hover {
    border: none;
}
.DayPicker_weekHeader {
    background: white;
    margin-top: -1%;
}

.DayPicker_weekHeader_li {
    visibility: hidden;
    font-size: 0;
}

.DayPicker_weekHeader_li::first-letter {
    visibility: visible;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.CalendarMonth_table {
    margin: 10% 0 0;
}

.DayPickerNavigation_button {
    top: 9px; 
}
.DayPickerNavigation_rightButton {
    right: 24px;
}.DayPickerNavigation_leftButton {
    left: 23px;
}