.accordion {
}
.accordion_header {
    cursor:pointer;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:space-between;
    background-color:#F2F2F2;
}
.accordion .accordion_content {
    overflow:hidden;
    transition:height 0.35s ease-out;
}
.dropdown_arrow {
    transition:transform 0.35s ease-out;
    padding:14px 12px 14px 0;
}
.open .dropdown_arrow {
    transform: rotateX(180deg);
}