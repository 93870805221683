.form_field_row{
    display: block;  
    font-size: 14px;   
  }

  
  .cursor_pointer {
    cursor: pointer;
  }
  
  .tooltip_icon {
    margin-left: 5px;
    height: 16px;
    width: 16px;
  }
  
  .error_text {
    color: red;
  }
  
  .input_label {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
    font-size: 16px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .input_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-width: 239px;
    width: 100%;
  }
  
  .form_control {
    width: 100%;
    border: 0;
    height: 32px; 
    outline: 0;
  }
  
  .form_group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
  }
  
  .selectDropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #999999;
    padding: 1px 16px;
    padding-right: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
  }
  .dropwown_position {
    top: 100%;
    margin-top: 0 !important;
  }
  
  .options {
    padding: 10px 16px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 0px;
    cursor: pointer;
    text-transform: capitalize;

  }
  
  .options:hover {
    background-color: #E6ECEE;
  }
  
  .options_wrapper {
    width: 100%;
    padding: 16px;
  }
  
 
  .disabled{
    pointer-events: none;
      background: #F2F2F2;
      color: #666 !important;
      border-bottom: 0;
      border-radius: 4px;
      border: 0 !important;
  }
  
  .disabled .selectArrow { 
    opacity: 0.5;
    pointer-events: none;
  }
  
  .selectArrow {
    transform: rotateX(0);
    transition: transform 350ms ease-out;
    margin-left: auto;
  
  }
  .selectArrowActive {
    transform: rotateX(180deg);
    transition: transform 350ms ease-out;
    margin-left: auto;
  
  }

  .dropdown {
    position: absolute;
    margin-top: 15px;
    max-height: 0;
    opacity: 0;
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out;
    min-width: 239px;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    overflow-y:auto;
    -webkit-box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
    top: 100%;
    margin-top: 0 !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .dropdown:-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .dropdown {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .isActive {
    max-height: 200px;
    opacity: 1;
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out;
    background-color: #ffffff;
    width: 100%;
  }
  
  .errorField{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .errorField .selectDropdown{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .errorField .isActive{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .error_text{
    color: #991B1B;
  }
  
  @media screen and (max-width: 1023px) {
    .selectDropdown {
      height: 48px;
      border: 1px solid #999;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      font-size: 16px;
      width: 100%;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .input_box {
      width: 100%;
    }
    .form_field_row {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .input_label {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .selectDropdown {
      height: 48px;
      border: 1px solid #999;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      font-size: 16px;
      width: 100%;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .input_box {
      width: 100%;
    }
    .form_field_row {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .input_label {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
  }
  
  .select_options {
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
  }