.form_field_row{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .cursor_pointer{
    cursor: pointer;
  }

  .error_text{
    color: red;
  }
  .input_label {
    flex-basis: 30%;
    font-size: 16px;
    height: 32px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .form_group{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .avatarWrapper{
    display: block;
    text-align: center;
    cursor: pointer;
  }

  .avatarDefault{
    width: 100%;
    height: 100%;
    background-color: #999999;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatarImage{
    width: 96px;
    height: 96px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #567783;  
    background-size: cover !important;  
  }
  .avatarImageAdd{
    background-color: #567783;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatarBtn{
    font-size: 12px;
    font-weight: 700;
    color:#3C5D69;
  }

  .marginBottom25{
    margin-bottom: 25px;
    font-size: 14px;
  }
  
  @media screen and (max-width:767px){
    .form_field_row{   
      flex-wrap: wrap;    
    }
    .input_label {
      flex-basis: 100%;
    }

  
  }