.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.documentation_container {
  flex-grow: 1;
}
.documentation_container_outlet {
  align-items: stretch;
  padding: 24px 24px 40px;
  gap: 24px;
}

.module_container {
  gap: 24px;
}
.top_section {
  gap: 8px;
}
.top_section_header {
  justify-content: space-between;
  align-items: center;
}

.header_buttons {
  gap: 16px;
  white-space: nowrap;
  align-items: center;
}

.line {
  border: 1px solid #ccc;
}
.module_information {
  gap: 24px;
}
.document_group_link {
  text-decoration: none;
  color: #999;
  font-weight: bold;
  padding-left: 16px;
}
.document_group_link.active {
  color: #000;
  border-left: 4px solid #567783;
  padding-left: 12px;
}

.group_select {
  border: 1px solid #999;
  padding: 12px 8px 12px 16px;
  font-size: var(--fs16px);
}

.flex_column.module_information > div {
  gap: 10px;
}
.add_document_confirmation_buttons {
  gap: 16px;
  justify-content: center;
  margin-top: 10%;
}
.manageButtonImage {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 16px;
}
.module_list {
  width: 20%;
  max-width: 210px;
  min-width: 180px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .documentation_container_outlet {
    padding: 16px 16px 40px;
  }
}
