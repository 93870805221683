.datepicker_container > div {
    display: flex;
    flex-direction: row;
}
.datepicker_container > div > label {
    flex-basis: 30%;
}
.datepicker_container > div > div {
    flex-basis: 70%;
}
.datepicker_container > div > div input {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none !important;
}
.datepicker_wrapper {
    width: 100%;
}
.datepicker_wrapper input {
    padding:6px 16px 6px 0;
    width: 100%;
    border: 1px solid #999;
}

@media screen and (max-width:767px) {
  .datepicker_container > div > label {
    flex-basis: 100%;
  }
}