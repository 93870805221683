.flex_row {
    display:flex;
    flex-direction:row;
}

.flex_column {
    display:flex;
    flex-direction:column;
}

.documentation_container {
    flex-grow:1;
}
.documentation_container_outlet {
    align-items:stretch;
    padding:24px 24px 40px;
    gap: 24px;
}

.flex_column.module_information > div {
    gap:10px;
}
.add_document_confirmation_buttons {
    gap:16px;
    justify-content: center;
    margin-top: 10%;
}
