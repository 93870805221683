.card.section-cards {
  justify-content: space-between;
  flex-flow: row wrap;
  background: var(--primaryGrey);
  height: fit-content;
  gap: 1%;
}

.section-card {
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex-basis: 31.8%;
  min-width: 274px;
  font-size: 14px;
  margin-top: 5px;
}


@media screen and (max-width:1023px){
  .section-card {    
    flex-basis: 48%;    
    margin-bottom: 1.5rem;
    
  }
  .section-card:last-child {  
    margin-bottom: 0;  
  }
}
@media screen and (max-width:767px){
  .section-card {
    flex-basis: 100%;
    
}
}