.flex_row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.flex_row {
	display: flex;
	align-items: center;
}

.accountDropdown {
	font-size: 14px;
}

.accountDropdown > div{
	margin: 20px;
}

.accountDropdown > div:first-child{
	font-weight: normal;
	color: black;
}

.accountDropdown > div:nth-child(n+2){
	color: #006487;
}

.accountModalBody {
	text-align: left;
}

.accountModalCard {
	background: #F2F2F2;
	border-radius: 16px;
	padding: 16px;
	margin: 16px 0px;
	font-size: 14px;
}

.settingsFormForDesktop {
	display: table;
	margin: 10px 0px;
}

.settingsFormForDesktop > div {
	display: table-row;
}

.settingsFormForDesktop > div > div {
	display: table-cell;
	padding: 10px 20px 10px 0px;
	white-space: nowrap;
}

.settingsFormForTabletAndMobile > div > div:first-child {
	font-weight: bold;
	margin: 10px 0px;
}
