.searchTbl{
  display: table;
  padding:24px 0;
  width:100%;
}
.searchCell{
  display: table-cell;
  vertical-align: top;
}
.searchCell:first-child{
  width:20%;
}
.searchCell:last-child{
  width:40%;
}
.searchKeyWord{
  font-size:24px;
  color:#00749E;
  font-weight:bold;
}
.fileSize{
  font-weight:normal;
  font-size:16px;
}
.dateTextTbl{
  display:block;
}


.dateTextLabel{
  font-size:16px;
  color:#808080;
  width: 112px;
    display: inline-block;
}
.dateText{
  font-size:16px;
}

.Highlight {
  background: rgba(0, 150, 205, 0.18);
  color:#00749E;
}

@media (max-width: 1023px) {
  .searchTbl{
    display: block;
    padding: 30px 0;
  }
  .searchCell{
    display: block;
    width: 100%;
  }
  .searchCell:first-child{
    padding-bottom: 24px;
  }
  .searchCell:last-child{
    width:100%;
    padding-top: 28px;
  }
}

@media (max-width:767px) {
  .dateTextTbl > span {
    display: block;
  }

  .dateText {
    padding-top: 4px;
    padding-bottom: 6px;
  }
}