.card {
  padding: 25px;
  width: 100%; 
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 32px;
  
}
.objecttag{
  margin: auto;
    display: block;
}