.row:nth-child(1) {
  align-items: flex-start !important;
}
.card.footer {
  background-color: #223F4B;
  color: white;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  font-size: 12px;
}
.text-white {
  color: white;
}
.no-underline {
  text-decoration: none;
}
.dark-blue {color: #3C5D69}
.footer .row {
  margin-bottom: 0px;
  height: auto;
  align-items: baseline;
  justify-content: space-between;
}
.footer .row:last-child {
  margin-bottom: 0px;
}

.text-fade {
  color: #9BB3BB;
}
.flexbase25 {
  flex-basis: 25%;
}
.flexbase33 {
  flex-basis: 33%;
}

.flexbase17 {
  flex-basis: 17%;
}

.marBot5 {
  margin-bottom: 5px;
}
.marRight5 {
  margin-right: 10px;
}
.marRight10 {
  margin-right: 10px;
}

@media screen and (max-width:767px){
  .footer .row {    
    display: block;
}

.footer .row-to-col { 
  margin-bottom: 1.25rem;
}
.footer .col-item {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0.75rem;
}
}