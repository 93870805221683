.flex_row {
  display:flex;
  flex-direction:row;
}
.flex_column {
  display:flex;
  flex-direction:column;
}



.header {
  gap:24px;
}
.header_title {
  font-weight:700;
  font-size:24px;
}
.header_content {
  gap:24px;
}
.group_header_text {
  font-size:var(--fs14px);
  gap:8px;
}
.not_desktop .add_button {
  order:0;
}
.not_desktop .manage_button {
  order:1;
}

.add_document_form {
  gap: 32px;
  font-size:var(--fs16px);
}

.add_document_form .add_document_field {
  gap:24px;
  align-items:center;
}
.add_document_form .add_document_field .field_label {
  flex-basis: 25%;
}
.add_document_form .add_document_field > input,
.add_document_form .add_document_field > select {
  flex-grow:1;
}
.add_document_form .add_document_field > input {
  background: #F2F2F2;
  border-radius: 4px;
  padding: 13px 0px 13px 16px;
  border:none;
}
.add_document_form .add_document_field > input.attachment_name {
  background:none;
  border-bottom:1px solid #999;
  font-size: var(--fs16px);
  border-radius:0;
}
.warning_text span {
  margin:0;   
  font-size:var(--fs14px);
  font-weight:300;
}
.select_container {
  border-bottom: 1px solid #999;
  font-size: var(--fs16px);
  flex-grow:1;
}
.select {
  justify-content:space-between;
  box-shadow: 0px 1px 0px #B3B3B3;
  padding: 4px 8px 4px 4px;
  cursor:pointer;
  font-size:var(--fs16px);
}
.select_options {
  gap:3px;
  padding:16px;
  background:#fff;
  border-radius:4px;
  z-index: 1;
}
.select_options > div {
  padding: 4px;
  cursor:pointer;
  padding:8px 16px;
  border-radius:4px;
}
.select_options > div:hover {
  background-color:#f2f2f2;
}
.checkbox_label {
  font-family: 'DIN Next LT Pro';
  font-size: var(--fs16px);
}
.delete_file {
  cursor:pointer;
}

.form_field_row{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.input {
  border: none;
  height: 32px;
  border-bottom: 1px solid #B3B3B3;
  flex-grow: 1;
  font-size: 16px;
  width: 100%;
  padding: 1px 4px;
}

.input:focus-visible{
  outline: none;
}

.small{
  color: #808080;
  font-weight: 400;
font-size: 12px;
line-height: 140%;
}

.input:disabled {
  border-bottom: 0;
  background-color: #F2F2F2;
  border-radius: 4px;
}
.cursor_pointer{
  cursor: pointer;
}
.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}

.input_label {
  flex-basis: 30%;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
}
.form_group{
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 70%;
  position: relative;
}

.errorField{
  background-color: #FEE2E2;
  color: #991B1B;
}
.error_text{
  color: #991B1B;
}

.deleteFile {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top:50%;
  transform: translateY(-50%);
}




@media screen and (max-width:767px){
  .form_field_row{   
    flex-wrap: wrap;    
  }
  .input_label {
    flex-basis: 100%;
  }
  .input{
    height: 48px;
    border: 1px solid #999;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }

}