.modulePermissions>div:first-child>div>div {
	white-space: nowrap;
}

.modulePermissions>div>div>div {
	background: white !important;
	width: 20%;
}

.modulePermissions>div>div>div:not(:first-child) {
	text-align: center;
}