.accordion {
  width: 100%;
  margin-bottom: 20px;
}

.panel__label {
  position: relative;
  display: flex;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 14px 24px;
  font-weight: bold;
  font-size: 14px;
  font-family: inherit;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  cursor: pointer;
  background-color: #F2F2F2;
  
}
.panel__label .pencil{
  margin-left: auto;
  padding-right: 28px;
}

.panel__label:focus {
  outline: none;
}

.arrow-accordian {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.panel__label .arrow-accordian {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel[aria-expanded='true'] .panel__content {
  opacity: 1;
}

.panel[aria-expanded='true'] .panel__label {
  color: #957029;
}

.panel[aria-expanded='true'] .panel__label .arrow-accordian {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.panel__inner {
  overflow: hidden;
  will-change: height;
  -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
  font-size: 14px;
  color: #756658;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear 0.18s;
  transition: opacity 0.3s linear 0.18s;
  margin: 0;
}

.panel:not(:last-child) {
  margin-bottom: 3px;
}

.panel_tbl {
  display: table;
  width: 100%;
  padding: 0px 25px;
  border-bottom: 1px solid #E6E6E6;
}

.panel_tbl .panel_tbl_cell {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
  padding: 11px 0px;
  color: #000;
  font-size: 14px;
}

.panel_tbl .panel_tbl_cell strong {
  font-size: 12px;
}

.panel_tbl:last-child {
  border-bottom: 0;
}

@media screen and (max-width:560px){
  .panel_tbl:first-child {
    padding-top: 15px;
  }
  .panel_tbl {
    display: table;
    width: 100%;
    padding: 0px 25px;
    border-bottom: 0;
    padding-bottom: 15px;

}
  .panel_tbl .panel_tbl_cell {
    display: block;
    width: 100%;    
    padding: 5px 0px;    
    font-size: 14px;
}
}
