.tooltip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  border-radius: 8px 0px 0px 0px;
  z-index: 3;
  /* for fixed position */
}
.tooltip {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  border-radius: 0px 8px 0px 0px;
  /* margin-top: 48px; */
  /* for fixed position */
  width: 0px; 
  transition: width 350ms ease-in-out;
}
.tooltip.active {
  width: 300px;
  transition: width 350ms ease-in-out;
}
.menu-toggle {
  flex-grow: 0 !important;
  justify-content: flex-start;
  white-space: nowrap;
}

.tooltip-item {
  display: flex;
  justify-content: space-between;
  flex-basis: auto;
  align-items: flex-start;
  padding: 12px 8px 12px 11px;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
}
.tooltip-item:focus-visible {
  outline: none;
}
.tooltip-item:hover {
  background: var(--primaryGrey);
}

.noHover:hover {
  background: var(--darkBlueButton) !important;
}
.active-location {
  background-color: var(--darkBlueButton);
}

.active-location-icon {
  filter:  brightness(0) invert(1);
}

.active-location-icon:hover {
  filter:  none;
}

.tooltip-menu {
  width: 0px;
  transition: width 350ms ease-in-out;
  /* for position fixed */
  position: fixed;
  z-index: 3;
}
.tooltip-menu.active {
  width: 300px;
  transition: width 350ms ease-in-out;
}
.tooltip-menu-items {
  padding: 0;
}

.tooltip-menu-txt {
  flex-grow: 1;
  opacity: 0;
  visibility: hidden;
  text-align: justify;
  margin-top: 3px;
}
.tooltip-menu-txt.active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 450ms;
}

.active-text {
  color: #ffffff;
  padding-left: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.text {
  padding-left: 16px;
  color: var(--primaryGreyFont);
  font-weight: 500;
  white-space: nowrap;
}
.close {
  background-color: #CDD9DD;
  border-radius: 24px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 24px 0 24px 24px;
}
.close-icon {
  height: 14px;
  width: 14px;
}

.tooltip-item a {
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  margin-left: 5px;
  width: 0;
}

.open-close {
  width: auto !important;
}

.tooltip-icon {
  pointer-events: none;
  height: 24px;
}
.flip {
  transform: rotateY(180deg);
}

.tooltip-content-wrapper{
  margin: 0 0 24px 24px;
}
.heading {
  font-weight: bold;
  font-weight: 28px;
  margin-bottom: 16px;
  width: 276px;
}
.paragraph {
  margin-bottom: 16px;
  width: 276px;
}
.sub-heading {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 14px;
  width: 276px;
}

@media screen and (max-width:423px) {

}
@media screen and (max-width:1023px) {

  .tooltip-wrapper {
    z-index: 10;
  }
  .tooltip-parent{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .tooltip-menu{
    width: 100%;
  }
  
    .tooltip{
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      transform: translateX(-100%);
      transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    
    .tooltip.active{
      width: 100%;
      transform: translateX(0%);
      transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      overflow: auto;
    }

}