.alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 16px;
    padding: 14px 18px;
    align-self:stretch;
    border-radius:8px;
  }
  
  .warning {
    background-color: #FEF3C7;
    border: 1px solid #FDE68A;
    color: #92400E;
  }
  
  .error {
    background-color: #FEE2E2;
    border: 1px solid #FECACA;
    color: #991B1B;
  }

  .info {
    background-color: #CBF1FF;
    border: 1px solid #BBDEEB;
    color: #00749E;
  }

  .success {
    background-color: #D1FAE5;
    border: 1px solid #A7F3D0;
    color: #065F46;
  }

  .success .icon {
    filter:none;
  }

  .message {
    margin-right: 8px;
  }
  
  .alertInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    filter: var(--svgFilterOrangeButton);
    display:none;
  }
  .isDesktop .icon {
    display:unset;
  }
  .icon img {
    max-width: 100%;
  }
