.DatePickerSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    background-color: #E6ECEE;
    margin-bottom: 8px;
}

.DatePickerCalendar__month {
    margin-bottom: 8px;
    padding: 8px 72px;
    justify-content: center;
    align-items: center;
}

.DatePickerSelector__icon {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.DatePickerSelector__date {
    font-weight: 700;
}

.DatePickerSelector__icon:hover {
    background-color: #e0e0e0;
}

.DatePickerSelector__iconLeft {
    transform: rotate(180deg);
}
