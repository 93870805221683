.relatedLinks {
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  margin-left: 48px;
  margin-right: 24px;
}

.relatedLinks_header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
}

.relatedLinks_content {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.relatedLinks_title {
  color: var(--darkBlueButton);
  font-size: 20px;
  font-weight: bold;
}
.darkLinks_title {
  color: var(--darkBlueButton);
  font-size: 14px;
  font-weight: bold;
}
.relatedLinks_paragraph {
  font-size: 14px;
  margin: 12px 0 24px 0;
  flex-grow: 1;
}