
.toggle {
    height:24px;
    width:48px;
    appearance:none;
    position:relative;
    
    /* border: 0.15em solid currentColor; */
    background-color:#B4C6CC;
    border-radius: 99px;
    cursor:pointer;
}
.toggle:before {
    position:absolute;
    content:"";
    top:4px;
    left:4px;
    height:16px;
    width:16px;
    background-color: #fff;
    border-radius:99px;
    transition: transform 0.15s;
}

.toggle:checked {
    background-color:#3C5D69;
}
.toggle:checked:before {
    transform: translateX(24px)
}

.toggle:disabled {
    background-color: #B3B3B3;
    cursor:inherit;
}
.toggle:disabled:before {
    background-color: #E6E6E6;
}

.toggle:disabled:checked:before {
    background-color: #808080;
}