.support {
  padding: 24px;
  gap: 24px;
  height: fit-content;
}

.support-header {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}
.support-body {
  margin-bottom: 24px;
}

@media screen and (max-width:1023px) {
  .card.support{
    display: block;
  }
  .card.support .row-to-col:first-child{
    margin-bottom: 2rem;
  }
  .support-header{
    font-size: var(--fs20px);
  }
}