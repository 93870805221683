.backLink{
    font-size:var(--fs14px);
    text-decoration: none;
    color: #3C5D69;
    font-weight: bold;
    align-items: center;
    display: flex;
    cursor: pointer;

}

.attachmentwrapper{
    display: flex;
    flex-wrap: wrap;
    margin-left: -1%;
    margin-right: -1%;
}
.small{
    color: #808080;
    font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  }
.title{
    padding: 30px 0 16px 0;
}
.subtitle{
    font-size: 20px;
    font-weight: bold;
}
.para{
    font-size: 16px;
}
.qrwrpper{
    margin-bottom: 30px;
    max-width: 798px;
}

.mb32{
    margin-bottom: 32px;
}
.mb24{
    margin-bottom: 24px;
}
.input_label {
    flex-basis: 30%;
    font-size: 16px;
    height: 32px;
    display: flex;
    align-items: center;
  }
.dropdownSelect #selectDropdown {
    font-size: 14px;
    background: white;
    border: 1px solid #999999;
    font-weight: normal;
}
.marginRight24{
    margin-right: 24px;
}
.label{
    font-size: 16px;
    margin-bottom: 10px;
    display:block;
}
.inlineFlex {
    display: inline-flex;
}

.flexCol{
    flex: 1;
    padding:10px;
}
.flex{
    display: flex;
    margin-left:-10px;
    margin-right:-10px;
 
}
select,
.input {
    padding: 6px 12px;    
    width: 100%;
    height: 32px;
    
    /* Shades/White */    
    background: #FFFFFF;
    /* Neutral/Gray 40 */    
    border: 1px solid #999999;
    border-radius: 4px;
  }
  
  .input:focus-visible{
    outline: none;
  }
.quoteRequestPartsWrp{
    display: flex;
    margin-bottom: 8px;
    text-align: left;
    align-items: center;
}
.input:disabled{
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
}
.clear{
    cursor: pointer;
    color:#3C5D69;
    font-size: 14px;
    font-weight: bold;
}
.quoteRequestPartsWrp .col1{
    width: 300px;
    margin-right: 10px;
}
.quoteRequestPartsWrp .col2{
    width: 300px;
    margin-left: 10px;
    margin-right: 10px;
}
.quoteRequestPartsWrp .col3{
    width: 70px;
    margin-left: 10px;   
    margin-right: 10px;   
}

.buttonMargin{
    margin-top: 30px !important;
}

.card{
    background-color: #fff;
    padding:24px;
    border-radius: 8px;
}

.errorField{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .errorField .selectDropdown{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .errorField .isActive{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .error_text{
    color: #991B1B;
  }

@media screen and (max-width: 767px){
    .card{       
        padding:24px 16px;       
    }
}

@media screen and (max-width:1200px){
    .para{
        width: 80%;        
    }
    
}
@media screen and (max-width:1023px){
    
    .addMoreBtn{
        width: 318px;
    }
    .quoteRequestPartsWrp .col1{
        width: 22%;
      
    }
    .quoteRequestPartsWrp .col2{
        width: 56%;
       
    }
    .quoteRequestPartsWrp .col3{
        width: 70px;
       
    }
    .para{
        width: 100%;        
    }
    select,
.input {
    padding: 6px 16px;    
    width: 100%;
    height: 48px;
    
    /* Shades/White */    
    background: #FFFFFF;
    /* Neutral/Gray 40 */    
    border: 1px solid #999999;
    border-radius: 4px;
  }
}
@media screen and (max-width:767px){
    .addMoreBtn{
        width: 100%;
    }
    .quoteRequestPartsWrp,
    .flex{
        display: flex;       
        flex-direction: column;
     
    }
    .inlineFlex {
        width: 100%;
        flex-direction:column;
    }
    .marginRight24{
        margin-right: 0;
        margin-bottom: 30px;
    }
    .quoteRequestPartsWrp{
        display: block;
        margin-bottom: 24px;
        text-align: left;
    }
    .quoteRequestPartsWrp .col1,
    .quoteRequestPartsWrp .col2,
    .quoteRequestPartsWrp .col3{
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px; 
    }
}