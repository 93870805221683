

.header {
    gap:24px;
}
.header_title {
    font-weight:700;
    font-size:24px;
}
.header_content {
    gap:24px;
}
.group_header_text {
    font-size:var(--fs14px);
    gap:8px;
}
.group_header_text p {
    font-size: var(--fs14px);
}
.external_link {
    font-weight:700;
    gap:10px;
    align-items:center;
    color:#000;
    text-decoration:none;
}
.external_link .image_wrapper {
    background-color:#CDD9DD;
    border-radius:96px;
    padding:4px;
}
.external_link img {
    height:17px;
}