/* styles to override component styles */
/* .search_keyword_container {
 width: 257px;
} */

.flex_column {
    display:flex;
    flex-direction:column;
}

.results {
    flex-grow:1;
}

.hr {
    margin: 32px 0 18px;
    color: #ccc;
    width: 100%;
    height: 1px;
}

.flex_row {
    display:flex;
    flex-direction:row;
}

.options {
    justify-content: space-between;
    align-items:center;
    gap:24px;
}

.is_mobile .options {
    align-items:stretch;
}

.Highlight {
    background: rgba(0, 150, 205, 0.18);
    color:#00749E;
  }

@media screen and (max-width: 1180px) {
}
@media screen and (max-width: 1023px) {
    .search_keyword_container{
        width: 257px;
    }
    .search_keyword_container > div {
        width: 100% !important;
    }
}
@media screen and (max-width: 767px) {
    .search_keyword_container {
        width: 100%;
       }
}