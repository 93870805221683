.flex_row {
	display: flex;
	flex-direction: row;
}

.flex_column {
	display: flex;
	flex-direction: column;
}

.header_buttons {
	gap: 16px;
	white-space: nowrap;
	align-items: center;
	align-self: flex-start;
}

.addEditForm input:not([type="file"]),
.addEditForm label {
	width: 100%;
	border: none;
	padding: 12px 0px 0px;
	height: 48px;
}

.addEditForm input:focus {
	outline: none;
}

.addEditForm .table>div>div>div {
	padding: 0px;
	height: unset;
}

.addEditForm .table>div>div>div:first-child {
	border: none !important;
	width: 0%;
	white-space: nowrap;
	padding-right: 30px;
}

.addEditForm .table>div>div>div:nth-child(2):not(.filecontainer):not(.hasHelperText) {
	border-color: gray !important;
}
.addEditForm .table>div>div>div:nth-child(2).hasHelperText {
	border: none !important;
}
.addEditForm .table>div>div>div:nth-child(2).hasHelperText input {
	border-bottom: 1px solid gray !important;
}
.addEditForm .table>div>div>div:nth-child(2).hasHelperText small {
	color: #808080;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
	margin-top: 8px;
	display: block;
}
.addEditForm .table>div>div>div:nth-child(2).filecontainer {
	border: none !important;
}

.filecontainer {
	padding: 12px 0px !important;
}

.filecontainer span {
	color: #3C5D69;
	font-weight: bold;
	cursor: pointer;
}

.listLink {
	color: #3C5D69;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
}

.plantDetails p {
	font-size: 14px;
}

.plantDetails .paragraph {
	margin: 20px 0px;
}

.editDetails h5 {
	margin: 0px;
}

.editDetails p {
	margin-top: 0px;
}

.editDetails>div>div {
	margin-bottom: 20px;
}