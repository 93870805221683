.unitWrapper{
  margin-bottom: 24px;
}
.unitWrapper label{
  margin-bottom: 8px;
  display: block;
}

.plant_info_header strong{
  font-size: 20px;
  
}
.plant_info_header {
  margin-bottom: 16px;
}
.plant_info_header p{
  font-size: 14px;
  margin: 0;
  
}
.flex{
  display:flex;
  width: 100%;
}
.justify_space_between{
  justify-content: space-between;
}

@media screen and (max-width:1023px){
  .flex > div,
  .addBtn{
    width: 49%;
  }
  
}
@media screen and (max-width:767px){
  .flex > div{
    /* margin-bottom: 24px; */
  }
  .flex > div,
  .addBtn{
    width: 100%;
  }
  .actionwrapper{
    display: flex;
    flex-direction: column;
    
  }
  .actionwrapper .nameplate-dropdown{
    margin-bottom: 24px;
  }
  
}
