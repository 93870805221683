.nameplate-card {
    padding: 24px;
    margin-bottom: 80px;
  }
  .backto {
    margin-bottom: 40px;
    align-items: center;
  }
  .go-back {
    cursor: pointer;
    color: var(--darkBlueButton);
    font-weight: bold;
    font-size: 14px;
  }
  .nameplate_container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: -1%;
      margin-right: -1%;
  }
  .nameplate_title {
    font-size: 20px;
    font-weight: bold;
    width: 246px;
    margin: 1%;
  }
  
  .nameplate_content {
    height: 100%;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-bottom: 24px;
    display: flex;
    flex-flow: column nowrap;
  }
  
  
  .col_item {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .flex_grow1 {
    flex-grow: 1 !important;
  }
  
  .nameplate_ellipse {
    height: 36px;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .nameplate_img {
    width: 100%;
    
  }
  
  .nameplate_text_container {
    margin: 8px;
    font-size: 14px;
  }
  
  .ellipsis {
    height: 22px;
    margin-right: 18px;
    cursor: pointer;
  }
  .grey_font {
    font-size: 12px;
    color: #808080;
  }
  .back-arrow {
    filter: var(--svgFilterDarkBlueButton);
    transform: rotateY(180deg);
    margin-top: 1px;
  }
  .pagination-left-arrow {
    filter: var(--svgFilterGrey);
    transform: rotateY(180deg);
    cursor: pointer;
  }
  .pagination-right-arrow {
    filter: var(--svgFilterGrey);
    cursor: pointer;
  }
  .index {
    color: #3c5d69;
    margin: 0 14px;
    cursor: pointer;
  }
  .darkBlue {
    background-color: var(--darkBlueButton);
    color: #ffffff;
    transition: background-color 250ms ease-in;
    
  }
  
  .deleteModalText{
    margin-bottom: 30px;
  }
  
  
  
  
  .close_modal {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .modalTitle {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }
  
  .flexGrow1 {
    flex-grow: 1 !important;
  }
  
  
  .imgPrevWrapper{
    /* padding-top: 80%; */
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  
  }
  .row{
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .tbl{
    display: table;
    padding-left: 30px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
  }
  .tbl_cell{
    display: table-cell;
    width:50%;
    color: #000;
    
  }
  .tbl_cell:first-child{
    width:50%;
    color: #666666;
  }
  .imageWrapper{
    max-height: 72vh;
    overflow-y: auto;
  }
  .imageWrapperLeft{
    width: 396px;
      height: 262px;
      overflow: auto;
  }
  .imageWrapperRight{
    width: 50%;
  }
  
  
  
  @media screen and (max-width:1366px){
    .nameplate_title {   
      width: 23%;
    }
  }
  
  @media screen and (max-width:1180px){
  
    .darkBlue{
      width: 276px;
      height: 48px;
    }
  
    .nameplate_title {   
      width: 31.3%;
    }
  }
  
  @media screen and (max-width:820px){
    .nameplate_title {   
      width: 48%;
    }
  }
  
  
  @media screen and (max-width:767px){
    .row{
      flex-wrap: wrap;
    }
    .tbl{   
      padding-left: 0px;
      margin-bottom: 10px;
      width: 100%;
      font-size: 14px;
    }
  
    .imageWrapperLeft{
      margin-bottom: 30px;
    }
  
    .imageWrapperLeft img{width:100%}
  .imageWrapperRight{
    width: 100%;
  }
    .nameplate_title {   
      width: 100%;
      margin-bottom: 20px;
    }
  }