
.flex_column {
    display:flex;
    flex-direction:column;
}

.flex_row {
    display:flex;
    flex-direction:row;
}

.report_container {
    gap:20px;
    flex-grow:1;
}

.results_container {
    font-size:var(--fs14px);
    gap:8px;
}
.is_desktop .results_container {
    gap:0;
}
.is_desktop .results_container > div {
    padding:12px 24px;
    border-bottom: 1px solid #F2F2F2;
    align-items: center;    
}
.is_desktop .results_container > div:last-child {
    border-bottom: 0
}

.accordion_heading {
    gap:14px;
    font-weight:700;
    padding-left:12px;
}

.accordion_title {
    gap:40px;
    flex-grow:1;
    padding:14px 0 14px 12px;
    color:#00749E;
}
.accordion_title.unread {
    font-weight:bold;
}
.result_container {
    gap:24px;
    padding: 24px 0 0;
    align-items:flex-start;
}
.is_tablet .result_container {
    gap:0;
    padding:0;
}
.data_point {
    align-self:stretch;
}
.is_tablet .data_point + .data_point {
    border-top: 1px solid #E6E6E6;
}
.data_point > div {
    padding: 8px 24px
}

.is_tablet .data_point > div {
    flex-basis:50%;
}
.more_actions {
    gap:8px;
    align-items:center;
    cursor:pointer;
}
.report_table {
    border-collapse:collapse;
}
.report_table th {
    text-align:left;
    background: #F2F2F2;
}
.report_table th, .report_table td {
    padding:12px;
}
.report_table .unread td {
    background: rgba(0, 116, 158, 0.05)
}
.report_table .unread .document_name,
.unread .report_table_accordion .more_actions {
    font-weight:bold;
}
.report_table.report_table_accordion th {
    background:none;
    padding-left:15px;
}
.report_table.report_table_accordion tr + tr > * {
    border-top:1px solid #E6E6E6;
}
.report_table_accordion {
    width:100%;
}
.unread .report_table_accordion tr > * {
    background: rgba(0, 116, 158, 0.05)
}
.disabled{
  pointer-events: none;
  background: #F2F2F2;
  color: #666 !important;
  border-bottom: 0;
  border-radius: 4px;
  border: 0 !important;
}
@media screen and (max-width: 767px) {
    .accordion_title {
        gap:10px;
    }
    .data_point > div {
        padding: 8px 0px;
    }
}