.form_field_row{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.input {
  border: none;
  height: 32px;
  border-bottom: 1px solid #B3B3B3;
  flex-grow: 1;
  font-size: 16px;
  width: 100%;
}
.input:focus-visible{
  outline: none;
}
.cursor_pointer{
  cursor: pointer;
}
.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}
.error_text{
  color: red;
}
.input_label {
  flex-basis: 30%;
  font-size: 16px;
  height: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.form_group{
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.img_fluid{
  max-width: 100%;
}
.map {
  width: 145px;
  height: 116px;
  overflow: hidden;
}
.change_photo {
  margin-top: 7px;
  font-size: 12px;
  cursor: pointer;
  color: #3C5D69;
  font-weight: 700;
}

@media screen and (max-width:767px){
  .form_field_row{   
    flex-wrap: wrap;    
  }
  .input_label {
    flex-basis: 100%;
  }
  .form_group{   
    flex-wrap: wrap;    
  
  }
  

}