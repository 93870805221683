/* Utility First Classes - Additions */
.text-primary {color: #E6ECEE;}
.text-neutral {color: #808080;}
.text-dkbluebutton {color:#3C5D69;}
.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
.hover\:text-gray-500:hover {
    --tw-bg-opacity: 1;
    color: rgb(160, 174, 192 / var(--tw-bg-opacity));
}
.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.flex {display: flex;}
.flex-col {flex-direction: column;}
.flex-start {align-items: flex-start;}
.col {flex-direction: column;}
.row {flex-direction: row;}
/*  Heights  */
.min-h-full {
    min-height: 100%;
}
.overlay {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
}
.relative {
    position: relative;
}

/*  Box Shadows */
.shadow-2xl {
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}
/*  Styles  */
.border-transparent {
    border-color: transparent;
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-gray-200 {
    border: 2px solid rgb(226, 232, 240);
}
.border {
    border: 1px solid;
}
.border-b-2 {
    border-bottom: 2px solid #f5f5f5;
}
.border-t-3 {
    border-top: 3px solid;
}
.no-underline {text-decoration: none;}
.dark-blue {color: #3C5D69}
.block {
    display: block;
}
.no-pointer {pointer-events: none;}
.z-2 {
    z-index: 2;
}
.z-10 {
    z-index: 10;
}
/*  Background Colors  */
.bg-white {background-color: #FFFFFF;}
.bg-indigo-600 {--tw-bg-opacity: 1;background-color: rgb(79 70 229 / var(--tw-bg-opacity));}
.bg-primary {background-color: #e6ecee;}
.bg-header {background-color: #f2f2f2;}

.bg-neutral {background-color: #CCCCCC;}
.bg-unread {background-color: #f2f8fa;}
.bg-read {background-color: #ffffff;}
.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(160, 174, 192);
    color:#1a202c;
    border: none;
}
.hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

/* Justify */
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.space-y-6>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

/* Height Settings */
.h-6 {height: 1.25rem;}
.h-16 {height: 4rem; /* 64px*/}
.h-20 {height: 5rem; /* 80px*/}
.h-450px {height: 450px;}
/*  :focus   */
.focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.align-text-bottom  {vertical-align: text-bottom;}
.items-center {align-items: center;}
.items-baseline {align-items: baseline;}
.transform {
    transform:scale(0.8);
    transform-origin:0 0;
}
.bottom {vertical-align: bottom;}

/* custom class overwriting from libraries */
.slide-pane__overlay {
    z-index: 10;
}