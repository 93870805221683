.actionToolTip {
  position: absolute;
  width: 144px;
  height: auto;
  right: 0;
  top: 22px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 8px;
  background: white;
  display: flex;
  flex-flow: row nowrap;
  z-index: 2;
  padding: 10px 0;
}
.action-item {
  flex-grow: 1;
  color: var(--darkBlueButton);
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 0;
  /* justify-content: space-around; */

  display: flex;
  width: 100%;
}
/* .action-item:hover {
  background-color: var(--greyButton);
} */
.action-icons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.action-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex-grow: 1;
}

.action-iconitem {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.action-icon {
  filter: var(--svgFilterDarkBlueButton);
  padding: 0 12px 0 24px;
  height: 24px;
  cursor: pointer;
}
.actionIconHover {
  filter: invert(20%) sepia(8%) saturate(2980%) hue-rotate(153deg) brightness(90%) contrast(87%);
}
.actionTextHover {
  color: #223F4B;
  text-decoration: underline;
}

