

.panelSpacing{
  /* padding: 30px 24px; */
  background-color: #fff;
}
.panel__label{
  font-size: 20px;
  line-height: 1.4;
  color: #000 !important;
  border-radius: 8px;
  margin-bottom: 16px;
 
}

.pagination_wrp{
  display: flex;
  align-items: center;
  padding: 24px 0 0 0;
}

.paginate{
  margin-left: auto;
}

.contactFilter{
  display: flex;
  align-items: center;
  margin: 0px 0 16px 0;
}


@media screen and (max-width:1200px){
  
}
@media screen and (max-width:1023px){
  .contactFilter{
    display: flex;
    align-items: center;
   
  }
}
@media screen and (max-width:767px){
  .pagination_wrp{
    display: flex;
    flex-flow: column;
  }
  .contactFilter{
    display: block;
    
  }
  .paginate{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px !important;
    margin-top: 0;
    order: 2;
  }
  .showAllBtn{
    order: 0;
    margin-bottom: 16px !important;
  }
  .paginationCount{
    order:1;
    margin-bottom: 16px;
    margin-top: 0;
  }
}