.flex_row {
	display: flex !important;
	flex-direction: row;
	justify-content: flex-start;
}

.title {
	margin-bottom: 10px;
	font-family: 'DIN Next LT Pro';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;
	color: #000000;
}

.thread_header {
	display: grid;
	justify-content: space-between;
	font-size: 14px;
	align-items: center;
	margin-bottom: 20px;
}

.thread_header_right,
.thread_header_right * {
	display: flex;
	align-items: center;
}

.post {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	padding: 16px 24px 24px;
	gap: 24px;

	min-height: 112px;

	background: #FFFFFF;

	/* Border */
	border: 1px solid #E6E6E6;
	border-radius: 4px;

	/* Inside auto layout */
	/* flex: none;
	order: 6;
	align-self: stretch;
	flex-grow: 0; */

	font-size: 14px;
	margin-bottom: 10px;
}
.post .unread {
	background: rgba(0, 116, 158, 0.05);
	font-weight: 700;
}

.deletedPost {
	background: #F2F2F2;
}

.attachment,
.attachment * {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #00749E;
}

.postHeader {
	display: flex;
	justify-content: space-between;
}

.editorWrapper {
	border-color: #999999;
	border-radius: 4px;
	margin-bottom: 5px;
}

.emptyEditor {
	max-height: 110px;
}

.paticipantList{
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}
.avatar{
	width:40px;
	height:40px;
	background-color: #567783;
	border-radius: 50%;
	margin-right: 8px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0px;
}
.headerCopy{
	max-width: 70%;
}
.contentContainer{
	padding-right: 5%;
}
@media screen and (max-width:1023px){
	.headerCopy{
		max-width: 100%;
	}
}