.ralterinfoWrapper{
    margin:40px 0 100px 0;
    display: flex;
    flex-direction: column;
}
.flex{
    display: flex;
}
.col{
    margin:0 30px;
    flex: 1 1 0;
    flex-direction: column;
}
.col h4{
    color: #3C5D69;
}
.col:first-child{
    margin-left:0;
    flex: 1 1 0;
    flex-direction: column;
}
.col:last-child{
    margin-right:0;
    flex: 1 1 0;
    flex-direction: column;
}
.subTitle{
    color:#000;
    text-transform: capitalize;
    margin-bottom:32px;
}
.inlineFlex {
    display: inline-flex;
}

@media screen and (max-width:1200px){
    .para{
        width: 80%;        
    }
}
@media screen and (max-width:1023px){
    .flex{
        flex-direction: column;
    }
    .col{
        margin:0 0 60px 0;
    }
    .col:last-child{
        margin-bottom:0;
    }
}

@media screen and (max-width:767px){
    .col{
        margin:0 0 40px 0;
    }
}