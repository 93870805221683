.App-header {
  height: 104px;
  display: flex;
  /* font-size: calc(10px + 2vmin); */
  font-size: 12px;
  padding: 0px 24px 0px 24px;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s ease-in;
}
.App-header.active {
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-out;
}
.pointer {
  cursor: pointer;
}
.App-body {
  background: var(--primaryGrey);
  min-height: 90vh;
  overflow: auto;
  margin: 0px 24px 0 24px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  padding-top: 48px;
}
.content {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: 48px;
  margin-right: 24px;
  flex-wrap: nowrap;
}

.content-footer {
  /* THIS IS CAUSING A SPACE BELOW THE FOOTER 
 margin-bottom: 1.5rem; */
  flex-wrap: nowrap;
}
.login-header {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  font-size: var(--fs40px);
  font-weight: bold;
}
.section-header {
  height: 115px;
  display: flex;
  align-items: center;
  font-size: var(--fs28px);
  font-weight: bold;
}
.card {
  display: flex;
  background-color: white;
  border-radius: 8px;
}

.report-card:nth-child(1) {
  margin-left: 0;
}
.report-card:last-child {
  margin-right: 0;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100%; */
}

.column-item {
  flex-grow: 1;
}

.loader {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
}
.arrow-wrapper {
  background-color: var(--greyButton);
  border-radius: 24px;
  padding: 4px 4px 1px;
  box-shadow: none;
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}
.arrow-wrapper:hover {
  background-color: #b4c6cc;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}
.arrow {
  height: 19px;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 24px;
  align-items: center;
}

.row:nth-child(1) {
  margin-bottom: 32px;
  height: 68px;
  align-items: center;
  justify-content: space-between;
}
.row:last-child {
  margin-bottom: 0;
}

.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}

.notify {
  margin-right: 8px;
  border-radius: 8px;
  color: white;
  padding: 2px 2px 3px 2px;
  width: 44px;
  text-align: center;
}

.notify.issue {
  background-color: #dc6914;
}
.notify.partsNServ {
  background-color: #006487;
}
.notify.techDoc {
  background-color: #91af1e;
}
.row-item {
  flex-grow: 1;
}

.title {
  font-size: 24px;
  height: 68px;
}

.tile-header {
  font-size: 24px;
  font-weight: bold;
  margin: 24px 0 8px 0;
}

.tile-paragraph {
  margin-bottom: 46px;
}

.tile-nav {
  color: var(--darkBlueButton);
  font-weight: bold;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.report-icon {
  height: 40px;
  margin-left: 10px;
}

.tile-img {
  height: 100%;
  width: 100%;
}

.MPR-margin {
  margin-bottom: 80px;
}

.padd24 {
  padding: 24px;
}
.marBot30 {
  margin-bottom: 30px;
}
.marBot61 {
  margin-bottom: 71px;
}
.height24 {
  height: 24px;
}

.tabItem.active {
  border-bottom: solid #3c5d69 2px;
  color: black;
  transition: border-bottom 150ms ease;
}
@media screen and (max-width: 1500px) {
  .content {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .breadcrumb {
    margin-bottom: 10px;
  }
  .login-header {
    font-size: var(--fs28px);
  }
  .section-header {
    height: 94px;
    font-size: var(--fs24px);
  }
  .App-header {
    height: auto;
    display: block;
  }
  .App-body {
    padding-top: 2rem;
  }

  .content {
    margin-left: 1.5rem;
  }
  .tile-header {
    font-size: var(--fs20px);
    margin-bottom: 15px;
  }
  .title {
    font-size: var(--fs20px);
    height: 48px;
  }
  .issue_prt_tech_wrp .primaryBtn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .row:nth-child(1) {
    height: auto;
  }
  .card {
    display: block;
  }
  .content {
    margin-left: 16px;
    margin-right: 16px;
  }
  .App-header {
    padding: 0 16px 0 16px;
  }
  .App-body {
    margin: 0px 16px 0 16px;
  }
}
