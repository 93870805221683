.primaryBtn {
  border-radius: 96px;
  padding: 8px 32px;
  font-size: var(--fs14px);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-dropdown {
  filter: var(--svgFilterDarkBlueButton);
  transition: filter 250ms ease-in;
  margin-left: 14px;
}

.primaryBtn:hover .button-dropdown {
  filter: none;
  transition: filter 250ms ease-in;
}

.button-dropdown-less {
  filter: var(--svgFilterDarkBlueButton);
  transform: rotateX(180deg);
  transition: filter 250ms ease-in;
  margin-left: 14px;
}

.primaryBtn:hover .button-dropdown-less {
  filter: none;
  transition: filter 250ms ease-in;

}

.redDelete {
  background-color: var(--redButton);
  color: #ffffff;
  transition: background-color 250ms ease-in;
}


.darkBlue {
  font-weight: 700;
  background-color: var(--darkBlueButton);
  color: #ffffff;
  transition: background-color 250ms ease-in;
}

.darkBlue:hover {
  @apply bg-dark-blue-600 text-white;
  transition: background-color 250ms ease-in;
}

.darkBlue:focus {
  @apply bg-dark-blue-600 text-white;
}

.whiteBtn {
  background: inherit;
  /* color: var(--darkBlueButton);
  border: var(--darkBlueButton) solid 1px; */
  color: #3C5D69;
  border: #3C5D69 solid 1px;
  font-weight: bold;
  box-shadow: none;
}

.whiteBtn img {
  filter: var(--svgFilterLightBlueButton);
}

.whiteBtn:hover {
  color: #223F4B;
  border: 1px solid #223F4B;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);; */
  transition: background-color 250ms ease-in, color 250ms ease-in, box-shadow 250ms ease-in, border 250ms ease-in;
}

.whiteBtn:hover img {
  filter: var(--svgFilterDarkBlueButton);
}

.whiteBtn:focus {
  color: #000000;
  border: 1px solid #000000;
}

.whiteBtn:focus img {
  filter: none;
}

button.whiteBtn:disabled {
  color: var(--secondaryGrey);
  border: 1px solid var(--secondaryGrey);
  background-color: var(--svgFilterWhite);
}

.whiteBtn:disabled img {
  filter: var(--svgFilterDisabledGrey);
}

.grey {
  background: var(--greyButton);
  box-shadow: none;
}

.grey:hover {
  background-color: #B4C6CC;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  transition: background-color 250ms ease-in, box-shadow 250ms ease-in;
}

button.darkBlue:disabled,
button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: var(--secondaryGrey);
  cursor: default;
  font-weight: 700;
}


@media screen and (max-width:767px) {
  .flex {
    flex-wrap: wrap;
    width: 100%;
  }

  .primaryBtn {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    height: 48px;

  }
}