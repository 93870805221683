.table {
	display: table;
	width: 100%;
}

/* Header */
.table>div:first-child {
	display: table-header-group;
	font-weight: 700;
}

/* Header-Cell */
.table>div:first-child>div>div {
	background: #F2F2F2;
}

/* Body */
.table>div:nth-child(2) {
	display: table-row-group;
}

.table>div>div {
	display: table-row;
}

/* Cell */
.table>div>div>div {
	padding: 12px;
	height: 48px;
	display: table-cell;
	border-bottom: 1px solid #F2F2F2 !important;
	font-size: 14px;
}