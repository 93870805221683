.form_field_row{
    display: block;  
    margin-bottom: 30px;
  }
  
  .input {
    padding: 6px 16px;    
    width: 100%;
    height: 32px;
    
    /* Shades/White */    
    background: #FFFFFF;
    /* Neutral/Gray 40 */    
    border: 1px solid #999999;
    border-radius: 4px;
  }
  
  .input:focus-visible{
    outline: none;
  }
  
  .small{
    color: #808080;
    font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  }
  
  .input:disabled {
    border: 1px solid #f2f2f2;
    background-color: #F2F2F2;
    border-radius: 4px;
  }
  .cursor_pointer{
    cursor: pointer;
  }
  .tooltip_icon {
    margin-left: 5px;
    height: 16px;
    width: 16px;
  }
  
  .input_label {
    flex-basis: 30%;
    font-size: 16px;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .form_group{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 70%;
  }
  
  .errorField{
    background-color: #FEE2E2;
    color: #991B1B;
  }
  .error_text{
    color: #991B1B;
  }
  
  @media screen and (max-width:1023px){
    .form_field_row{   
      flex-wrap: wrap;    
    }
    .input_label {
      flex-basis: 100%;
      height: auto;
      margin-bottom: 5px;
    }
    .input{
      height: 48px;
      border: 1px solid #999;
      flex-grow: 1;
      font-size: 16px;
      width: 100%;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
  
  }
  @media screen and (max-width:767px){
    .form_field_row{   
      flex-wrap: wrap;    
    }
    .input_label {
      flex-basis: 100%;
    }
    .input{
      height: 48px;
      border: 1px solid #999;
      flex-grow: 1;
      font-size: 16px;
      width: 100%;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
  
  }