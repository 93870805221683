.sidebar-mobile-wrapper {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px 20px;
  z-index: 1;
  background-color: #fff;
}
.sidebar-mobile-wrapper nav {
  height: 100%;
  overflow-y: auto;
}

.submobile-menu,
.mobile-nav-links {
  padding: 12px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  /* background-color: red; */
  margin-bottom: 10px;
  font-weight: bold;
  text-decoration: none;
  color: #006487;
}
.submobile-menu {
  padding-left: 50px;
}
.menu-icon {
  margin-right: 10px;
}
.menu-toggle-icon {
  margin-left: auto;
}
.has-dropdown {
  color: #666666;
  cursor: pointer;
}
.nav-dropdown {
  opacity: 1;
  visibility: visible;
  max-height: 400px;
  transition: all 0.3s linear;
}
.hide.nav-dropdown {
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  transition: all 0.3s linear;
}
.menu-toggle-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
.menu-toggle-icon.active {
  transform: rotate(90deg);
}
.active-menu {
  background-color: var(--darkBlueButton);
}
.active-menu img {
  filter: brightness(0) invert(1);
}
.active-menu.mobile-nav-links {
  color: #fff;
}

.sidebar-mobile-wrapper {
  padding: 0 16px 16px;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  /* transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); */
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.sidebar-mobile-wrapper.active {
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
  /* transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.App-header .input-box {
  margin: 24px 0;
}
.input-box.keysearch {
  margin-right: 0;
}
.mobile-flex-column {
  display: flex;
  flex-direction: column;
}
.mobile-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mobile-grow {
  flex-grow: 1;
}
.bold {
  font-weight: bold;
}
.mobile-hr {
  margin: 16px 0;
}
.mobile-setting-links {
  padding: 12px 0 12px 0px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 4px;
  font-weight: bold;
  text-decoration: none;
}
.home-blue {
  color: #006487;
}
.paddingL-12 {
  padding-left: 12px;
}

@media screen and (min-width: 768px) {
  .App-header  .input-box {
    margin: 16px 0;
  }
  .sidebar-mobile-wrapper {
    padding: 0 16px 16px;
  }
}
@media screen and (min-width: 1024px) {
  .input-box.keysearch {
    margin-right: 24px;
  }
}
