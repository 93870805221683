 .btn {
  background: #fff;
  /* border: 1px solid #000; */
  height: 36px;
  padding: 10px 0 10px 10px;
  border: 0;
  cursor: pointer;
  outline: 0;
}

.btn img {
  max-height: 18px;
}

.right_btn {
  padding: 10px 10px 10px 0;
}

.formcontrol {
  width: 100%;
  border: 0;
  height: 36px;
  padding: 0 5px;
  outline: 0;
  background-color: #fff;
}

.inputsearchbox {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;  
  
  min-width: 239px;
  background: white !important;
    border: 1px solid #999999;
    font-size: 14px;
    height: 36px;
  
}

@media screen and (max-width:1180px){
  .inputsearchbox{
    height: 48px;
    margin: 0;
    width: 300px;
   }
}
@media screen and (max-width:1023px){
   
}

@media screen and (max-width:767px){
   .inputsearchbox{ 
    width: 100%;
    margin-top: 16px;

   }
}