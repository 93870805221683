
.flex_row {
    display:flex;
    flex-direction:row;
}

.search_keyword {
	border: none;
	outline: none;
	line-height: 28px;
	width: 100%;
}

.search_keyword_container {
	border: 1px solid #999;
	border-radius: 4px;
	padding-left: 4px;
	padding-right: 7px;
	gap: 8px;
	align-items: center;
	width: 239px;
}


@media screen and (max-width: 1023px) {
  .search_keyword_container{
      /* height: 48px; */
      width: 320px;
  }
}

@media screen and (max-width: 767px) {
  .search_keyword_container{
      /* height: 48px; */
      width: 100%;
  }
}