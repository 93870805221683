
.flex_column {
    display: flex;
    flex-direction: column;
    flex: none;
}

.flex_row {
    display: flex;
    flex-direction: row;
    flex: none;
}

.manage_document_groups {
    padding: 24px;
    padding-top: 0;
    background: #FFFFFF;
    border-radius: 8px;
    order: 1;
    align-items: flex-start;
    gap:24px;
    flex-grow:0;
}
.intro {
    padding: 0px;
    order: 0;
    align-self:stretch;
    align-items: flex-start;
    gap:24px;
    flex-grow:0;
}

.back_container {
    align-items: center;
    padding: 0px;
    gap: 10px;
    order: 0;
    flex-grow: 0;
    color: #3C5D69;
    font-weight:700;
    font-size:var(--fs14px);
    cursor:pointer;
}

.section_title_cta {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    order: 2;
    flex-grow: 0;
    align-self:stretch;
    justify-content: space-between;
}
.section_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    order: 0;
    flex-grow: 1;
}
.section_heading {
    font-weight: 700;
    font-size: var(--fs24px);
    line-height: 140%;
    color: #000000;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.section_description {
    font-weight: 400;
    font-size: var(--fs16px);
    line-height: 140%;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.cta_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.report_types_container {
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.report_type_list_item {
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.move_icon {
    align-items: center;
    padding: 40px 0px;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    cursor:move;
    touch-action: none;
}
.report_type_inner {
    box-sizing: border-box;
    align-items: flex-start;
    padding: 24px 16px 24px 24px;
    gap: 8px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    order: 1;
    flex-grow: 1;
}
.input_wrapper {
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    order: 0;
    flex-grow: 1;
}
.input_wrapper input {
    box-sizing: border-box;
    align-items: flex-start;
    padding: 6px 16px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 4px;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    color: #808080;
}
.help_text {
    font-size: 12px;
    line-height: 140%;
    color: #666666;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.edit_icon {
    flex:none;
    order:1;
    flex-grow:0;
    cursor:pointer;
}
.flex_column > .input_wrapper {
    align-self:stretch;
}
.generate_usage_report_link {
    margin-top:-24px;
    align-items:center;
    order: 1;
    align-self:stretch;
    flex-grow:0;
    font-weight: 700;
    font-size: var(--fs14px);
    color: #3C5D69;
}
.edit_button_group {
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.report_type_inner.flex_column {
    gap:32px;
}
.edit_left_button_group {
    flex-grow:0;
    order:0;
    gap:16px;
    padding:0;
    align-items:flex-start;
}
.modal_body_input_group {
    align-items:flex-start;
    gap:24px;
    align-self:stretch;
}
.modal_body_input_container {
    gap:8px;
    order:1;
    flex-grow:1;
    align-items:flex-start;
}
.modal_body_input {
    display:flex;
    flex-direction:row;
    border:none;
    order:1;
    padding: 5px 8px 5px 4px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #B3B3B3;
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}

.modal_button_group {
    align-items:center;
    gap:16px;
    order:0;
    flex-grow:0;
}
.modal_footer_required_fields {
    font-size:14px;
    color:#000;
    flex:none;
    order:1;
}
.done_button {
    border:none;
    background:none;
    align-items: center;
    padding: 0px;
    gap: 10px;
    order: 2;
    flex-grow: 0;
    font-weight:700;
    font-size: var(--fs14px);
    color: #3C5D69;
    flex: none;
    cursor:pointer;
}

.deleted_group_modal_button_group {
    align-items:center;
    gap:16px;
    order:0;
    flex-grow:1;
    justify-content: space-around;
}


.is_tablet .section_title_cta,
.is_mobile .section_title_cta,
.is_mobile .edit_button_group,
.is_mobile .edit_left_button_group,
.is_mobile .modal_body_input_group,
.is_mobile .modal_button_group,
.is_mobile .modal_footer {
    flex-direction: column;
}
.is_mobile .cta_container,
.is_mobile .modal_body_input_container {
    align-self:stretch;
}
.is_mobile .edit_left_button_group {
    align-self: stretch;
    gap:0;
}
.is_mobile .modal_footer {
    padding:0;
    gap:16px;
}
.is_mobile .modal_button_group {
    order:1;
    gap:0;
    align-self:stretch;
}
.is_mobile .modal_footer_required_fields {
    order:0;
}
