.dataWrp {
  font-size: 0.875rem;
  line-height: 19.5px;
}

.dataWrp .dataLabel {
  padding-bottom: 4px;
}

.dataValueNumber {
  font-size: var(--fs40px);
  font-weight: 700;
  line-height: 50px;
}

.dataValueNumber:after {
  content: "MW";
  font-size: var(--fs12px);
  position: relative;
  top: -5px;
  left: 8px;
  vertical-align: super;
}
.error_state {
  color: var(--errorRed);
}
