.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.header {
    gap: 24px;
    justify-content: space-between;
}

.header_title {
    font-weight: 700;
    font-size: 24px;
}

.header_content {
    gap: 24px;
}

.group_header_text {
    font-size: var(--fs14px);
    gap: 8px;
}

.not_desktop .add_button {
    order: 0;
}

.not_desktop .manage_button {
    order: 1;
}

.add_document_form {
    gap: 24px;
    font-size: var(--fs16px);
}

.add_document_form .add_document_field {
    gap: 24px;
    align-items: center;
}

.add_document_form .add_document_field .field_label {
    flex-basis: 25%;
}

.add_document_form .add_document_field>input,
.add_document_form .add_document_field>select {
    flex-grow: 1;
}

.add_document_form .add_document_field>input {
    background: #F2F2F2;
    border-radius: 4px;
    padding: 13px 0px 13px 16px;
    border: none;
}

.add_document_form .add_document_field>input.attachment_name {
    background: none;
    border-bottom: 1px solid #999;
    font-size: var(--fs16px);
    border-radius: 0;
}

.add_document_form .warning_text p {
    margin: 0;
    font-family: 'DIN Next LT Pro';
    font-size: var(--fs14px);
    font-weight: 300;
    color: black;
}

.select_container {
    border-bottom: 1px solid #999;
    font-size: var(--fs16px);
    flex-grow: 1;
}

.selected {
    color: var(--primaryGreyFont);
}

.placeholder {
    color: var(--secondaryGreyFont);
}

.select {
    justify-content: space-between;
    padding: 4px 8px 4px 4px;
    cursor: pointer;
    font-size: var(--fs16px);
}

.select_options {
    gap: 3px;
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    max-height: 30%;
    z-index: 1;
    margin-right: 30px;
    overflow: scroll !important;
    /* trying to address blurry dropdown option text esp in chrome */
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-25%) translateY(-25%);
    -moz-transform: translateX(-25%) translateY(-25%);
    -ms-transform: translateX(-25%) translateY(-25%);
    transform: translateX(-25%) translateY(-25%);
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

.select_options::-webkit-scrollbar {
    display: none;
}

.select_options>div {
    color: var(--primaryGreyFont);
    padding: 4px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
}

.select_options>div:hover {
    background-color: #f2f2f2;
}

.checkbox_label {
    font-family: 'DIN Next LT Pro';
    font-size: var(--fs16px);
}

.delete_file {
    cursor: pointer;
}