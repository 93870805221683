.sitecontact{
    background-color: #fff;
    padding:24px ;
    border-radius: 8px;
}
.row{
    display: flex;
    margin-bottom: 30px;
}
.col{
    width: 65%;
}
.col:last-child{
    width: auto;
    margin-left: auto;
}

@media screen and (max-width:1023px) {
    .row{
        display: block;
    }
    .col{
        width: 100%;
    }
    .col:last-child{
        width: 100%;
        margin-left:unset;
    }
}
@media screen and (max-width:767px) {
    .sitecontact{
        background-color: #fff;
        padding:24px 16px ;
    }
}