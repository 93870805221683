.margin_bottom{
    margin-bottom: 15px;
}
p{
    font-size: 16px;
    color: #000;
    font-weight: 400;
}
.button_wrapper{
    max-width: 200px;
    margin-top: 15px;

}
.button_wrapper .darkBlue {
    text-decoration: none;
}
.button_wrapper a{
    text-decoration: none;
}