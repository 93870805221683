.flex-1 {
    flex-grow: 1;
}
.center {
    text-align: center;
}
.h-6 {
    height: 1.5rem; /*24px*/
}
.p-6 {
    padding: 1.5rem;  /*24px*/
}
.mb-10 {
    margin-bottom: 2.5rem; /*40px*/
}
.mb-12 {
    margin-bottom: 3rem!important; /*48px*/
}
.mb-14 {
    margin-bottom: 3.5rem; /*56px*/
}
.grow {
    flex-grow: 1;
}
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.flex-nowrap {
    flex-wrap: nowrap;
}

    